import AxiosInstance from "../axios.service";

// ACTION TYPES
export const FETCH_NOTIFICATIONS = "fetchNotifications";
export const MARK_AS_READ = "markAsRead";
export const DELETE_NOTIFICATION = "deleteNotification";
export const DELETE_ALL_NOTIFICATIONS = "deleteAllNotifications";
export const GET_UNREAD_NOTIFICATIONS_COUNT = "getUnreadNotificationsCount";

// MUTATION TYPES
const SET_NOTIFICATIONS = "setNotifications";
const ADD_NOTIFICATION = "addNotification";
const REMOVE_NOTIFICATION = "removeNotification";
const REMOVE_ALL_NOTIFICATIONS = "removeAllNotifications";
const MARK_AS_READ_NOTIFICATIONS = "markAsReadNotifications";

// STATE
const state = {
  notifications: [],
};

// GETTERS
const getters = {
  notifications(state) {
    return state.notifications;
  },
  unreadNotificationsCount(state) {
    return state.notifications.filter(
      (notification) => notification.read_at === null
    ).length;
  },
};

// ACTIONS
const actions = {
  [FETCH_NOTIFICATIONS]({ commit }) {
    return new Promise((resolve, reject) => {
      AxiosInstance.get("/notifications")
        .then(({ data }) => {
          commit(SET_NOTIFICATIONS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [MARK_AS_READ]({ commit }) {
    return new Promise((resolve, reject) => {
      AxiosInstance.patch("/notifications")
        .then(({ data }) => {
          commit(MARK_AS_READ_NOTIFICATIONS);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [DELETE_NOTIFICATION]({ commit }, notificationId) {
    return new Promise((resolve, reject) => {
      AxiosInstance.delete(`/notifications/${notificationId}`)
        .then(({ data }) => {
          commit(REMOVE_NOTIFICATION, notificationId);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [DELETE_ALL_NOTIFICATIONS]({ commit }) {
    return new Promise((resolve, reject) => {
      AxiosInstance.delete(`/notifications`)
        .then(({ data }) => {
          commit(REMOVE_ALL_NOTIFICATIONS);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [GET_UNREAD_NOTIFICATIONS_COUNT]() {
    return new Promise((resolve, reject) => {
      AxiosInstance.get(`/notifications/unread-count`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
};

// MUTATIONS
const mutations = {
  [SET_NOTIFICATIONS](state, notifications) {
    state.notifications = notifications;
  },
  [ADD_NOTIFICATION](state, notification) {
    state.notifications.unshift(notification);
  },
  [REMOVE_NOTIFICATION](state, notificationId) {
    state.notifications = state.notifications.filter(
      (notification) => notification.id !== notificationId
    );
  },
  [REMOVE_ALL_NOTIFICATIONS](state) {
    state.notifications = [];
  },
  [MARK_AS_READ_NOTIFICATIONS](state) {
    state.notifications = state.notifications.map((notification) => {
      notification.read_at = new Date(Date.now());
      return notification;
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
