import RolesMock from "@/core/mock/roles.json";

// action types
export const FETCH_ROLES = "fetchRoles";
export const CURRENT_ROLE = "currentRole";

// mutations types
const SET_ROLES = "setRoles";
const SET_CURRENT_ROLE = "setCurrentRole";

const state = {
  roles: [],
  currentRole: 1,
};

const getters = {
  roles: (state) =>
    state.roles.map((role) => ({ value: role.id, text: role.name })),
  currentRole: (state) => state.currentRole,
};

const actions = {
  [FETCH_ROLES]({ commit }) {
    commit(SET_ROLES, RolesMock);
  },
  [CURRENT_ROLE]({ commit }, { role, router }) {
    switch (role) {
      case 1:
        router.push("/admin/leads");
        break;
      case 2:
        router.push("/user/offers");
        break;
    }

    commit(SET_CURRENT_ROLE, role);
  },
};

const mutations = {
  [SET_ROLES](state, roles) {
    state.roles = roles;
  },
  [SET_CURRENT_ROLE](state, role) {
    state.currentRole = role;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
