import AxiosInstance from "@/core/services/axios.service";

//Action Types
export const FETCH_SM_CLASSES = "fetchSmClasses";
export const DELETE_SM_CLASS = "deleteSmClass";
export const GET_SM_CLASS = "getSmClass";
export const CREATE_SM_CLASS = "createSmClass";
export const UPDATE_SM_CLASS = "updateSmClass";

//Mutation Types
const SET_SM_CLASSES = "setSmClasses";
const REMOVE_SM_CLASS = "removeSmClass";
const SET_SM_CLASS = "setSmClass";
const ADD_SM_CLASS = "addSmClass";
const EDIT_SM_CLASSES = "editSmClasses";

const state = {
  smClasses: [],
  smClass: {},
};

const getters = {
  smClasses(state) {
    return state.smClasses;
  },
  smClass(state) {
    return state.smClass;
  },
};

const actions = {
  [FETCH_SM_CLASSES]({ commit }) {
    return new Promise((resolve, reject) => {
      AxiosInstance.get("sm-classes")
        .then(({ data }) => {
          commit(SET_SM_CLASSES, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [DELETE_SM_CLASS]({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      AxiosInstance.delete(`sm-classes/${id}`)
        .then(({ data }) => {
          commit(REMOVE_SM_CLASS, id);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [GET_SM_CLASS]({ commit }, id) {
    return new Promise((resolve, reject) => {
      AxiosInstance.get(`sm-classes/${id}`)
        .then(({ data }) => {
          commit(SET_SM_CLASS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [CREATE_SM_CLASS]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AxiosInstance.post("sm-classes", payload)
        .then(({ data }) => {
          commit(ADD_SM_CLASS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [UPDATE_SM_CLASS]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AxiosInstance.put(`sm-classes/${payload.id}`, payload)
        .then(({ data }) => {
          commit(EDIT_SM_CLASSES, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
};

const mutations = {
  [SET_SM_CLASSES](state, smClasses) {
    state.smClasses = smClasses;
  },
  [REMOVE_SM_CLASS](state, id) {
    state.smClasses = state.smClasses.filter((smClass) => smClass.id !== id);
  },
  [SET_SM_CLASS](state, smClass) {
    state.smClass = smClass;
  },
  [ADD_SM_CLASS](state, smClass) {
    state.smClasses.unshift(smClass);
  },
  [EDIT_SM_CLASSES](state, smClass) {
    const index = state.smClasses.findIndex((item) => item.id === smClass.id);
    state.smClasses.splice(index, 1, smClass);
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
