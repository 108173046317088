// Admin Router

import { RouterLeads, RouterMatch } from "./leads/_index";

import { default as RouterUsers } from "./users/router-users";
import { default as RouterStudents } from "./users/router-students";
import { default as RouterReminder } from "./reminder/router-reminder";
import { default as RouterDefinitions } from "./definitions/_index";
import { default as RouterNotifications } from "./notification/router-notifications";
import { default as RouterReports } from "./reports/router-reports";
import { default as RouterAgentManagement } from "./agents/router-agent-management";

export default {
  RouterLeads,
  RouterMatch,
  RouterUsers,
  RouterReminder,
  RouterDefinitions,
  RouterStudents,
  RouterNotifications,
  RouterReports,
  RouterAgentManagement,
};
