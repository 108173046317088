import RouterOffers from "./offers/router-offers";
import RouterDocuments from "./documents/router-documents";
import RouterPayments from "./payments/router-payments";
import RouterRegister from "./register/router-register";

export default {
  RouterOffers,
  RouterDocuments,
  RouterPayments,
  RouterRegister,
};
