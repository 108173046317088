export default [
  {
    path: "registration-periods",
    name: "def-registration-periods",
    component: () =>
      import(
        "@/view/pages/definitions/registration-periods/RegistrationPeriodList"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "registration-periods/edit/:id?",
    name: "def-registration-periods-edit",
    component: () =>
      import(
        "@/view/pages/definitions/registration-periods/RegistrationPeriodEdit"
      ),
    meta: {
      requiresAuth: true,
    },
  },
];
