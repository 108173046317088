import AxiosInstance from "@/core/services/axios.service";

// Action Types
export const FETCH_LEADS = "fetchLeads";
export const GET_LEAD = "getLead";
export const UPDATE_LEAD = "updateLead";
export const CREATE_LEAD = "createLead";
export const DELETE_LEAD = "removeLead";
export const UPDATE_LEAD_AGENT = "updateLeadAgent";
export const UPDATE_LEAD_STATUS = "updateLeadStatus";
export const UPDATE_LEADS_NOTES_COUNT = "updateLeadsNotesCount";
export const NOT_REACHED = "notReached";
export const REACHED = "reached";

// Mutations Types
const SET_LEADS = "setLeads";
const SET_LEAD = "setLead";
const ADD_LEAD = "addLead";
const EDIT_LEAD = "editLead";
const PURGE_LEAD = "purgeLead";
const EDIT_LEAD_STATUS = "editLeadStatus";
const EDIT_LEADS_NOTES_COUNT = "editLeadsNotesCount";
const EDIT_LEAD_AGENT = "editLeadAgent";

const state = {
  leads: [],
  lead: {},
  leadStatus: [],
};

const getters = {
  leads(state) {
    return state.leads;
  },
  getCurrentLead(state) {
    return state.lead;
  },
  leadStatus(state) {
    return state.leadStatus;
  },
};

const actions = {
  [FETCH_LEADS]({ commit }) {
    return new Promise((resolve, reject) => {
      AxiosInstance.get("leads")
        .then(({ data }) => {
          commit(SET_LEADS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [GET_LEAD]({ commit }, id) {
    return new Promise((resolve, reject) => {
      AxiosInstance.get(`leads/${id}`)
        .then(({ data }) => {
          commit(SET_LEAD, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [UPDATE_LEAD]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AxiosInstance.put(`leads/${payload.id}`, payload)
        .then(({ data }) => {
          commit(EDIT_LEAD, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [DELETE_LEAD]({ commit }, item) {
    return new Promise((resolve, reject) => {
      AxiosInstance.delete(`leads/${item.id}`)
        .then(({ data }) => {
          commit(PURGE_LEAD, item);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [CREATE_LEAD]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AxiosInstance.post("leads/storeByCrm", payload)
        .then(({ data }) => {
          commit(ADD_LEAD, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [UPDATE_LEAD_AGENT]({ commit }, { id, agent_id, fromLeadShowPage }) {
    return new Promise((resolve, reject) => {
      AxiosInstance.patch(`leads/${id}/agent`, { agent_id: agent_id })
        .then(({ data }) => {
          commit(EDIT_LEAD_AGENT, {
            payload: data,
            fromLeadShowPage: fromLeadShowPage,
          });
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [UPDATE_LEAD_STATUS]({ commit }, { id, status, fromLeadShowPage }) {
    return new Promise((resolve, reject) => {
      AxiosInstance.patch(`leads/${id}/status`, { status: status })
        .then(({ data }) => {
          commit(EDIT_LEAD_STATUS, { id, status, fromLeadShowPage });
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [UPDATE_LEADS_NOTES_COUNT]({ commit }, { id, note_type, fromLeadShowPage }) {
    commit(EDIT_LEADS_NOTES_COUNT, { id, note_type, fromLeadShowPage });
  },
  [NOT_REACHED]({ commit }, { id, fromLeadShowPage }) {
    return new Promise((resolve, reject) => {
      AxiosInstance.post(`leads/${id}/not_reached`)
        .then(({ data }) => {
          commit(EDIT_LEAD_STATUS, { id, status: data, fromLeadShowPage });
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [REACHED]({ commit }, { id, fromLeadShowPage }) {
    return new Promise((resolve, reject) => {
      AxiosInstance.post(`leads/${id}/reached`)
        .then(({ data }) => {
          commit(EDIT_LEAD_STATUS, { id, status: data, fromLeadShowPage });
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
};

const mutations = {
  [SET_LEADS](state, leads) {
    state.leads = leads;
  },
  [SET_LEAD](state, lead) {
    state.lead = lead;
  },
  [EDIT_LEAD](state, payload) {
    let lead = state.leads.find((lead) => lead.id === payload.id);
    state.leads.splice(state.leads.indexOf(lead), 1, payload);
  },
  [EDIT_LEAD_AGENT](state, { payload, fromLeadShowPage }) {
    const availableStatus = [1, 2];
    let status;
    if (!availableStatus.includes(payload.status)) {
      status = payload.status;
    } else {
      status = 6;
    }
    let lead;
    if (!fromLeadShowPage) {
      lead = state.leads.find((lead) => lead.id === payload.id);
      payload.status = status;
      state.leads.splice(state.leads.indexOf(lead), 1, payload);
    } else {
      payload.status = status;
      state.lead = payload;
    }
  },
  [ADD_LEAD](state, payload) {
    state.leads.unshift(payload);
  },
  [PURGE_LEAD](state, item) {
    state.leads.filter((lead) => lead.id !== item.id);
  },
  [EDIT_LEAD_STATUS](state, { id, status, fromLeadShowPage }) {
    let lead;
    if (!fromLeadShowPage) {
      lead = state.leads.find((lead) => lead.id === id);
      lead.status = status;
      state.leads.splice(state.leads.indexOf(lead), 1, lead);
    } else {
      lead = state.lead;
      lead.status = status;
      state.lead = lead;
    }
  },
  [EDIT_LEADS_NOTES_COUNT](state, { id, note_type, fromLeadShowPage }) {
    let lead = state.leads.find((lead) => lead.id === parseInt(id));
    if (fromLeadShowPage == 0) {
      if (note_type === "note") {
        lead.notes_count++;
      } else {
        lead.call_records_count++;
      }
      state.leads.splice(state.leads.indexOf(lead), 1, lead);
    } else {
      if (note_type === "note") {
        state.lead.notes_count++;
      } else {
        state.lead.call_records_count++;
      }
    }
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
