import DefinitionChannels from "./channels";
import DefinitionPackages from "./packages";
import DefinitionTrainings from "./trainings";
import DefinitionCampaigns from "./campaigns";
import DefinitionMessageTemplates from "./message-templates";
import DefinitionInterestRate from "./interest-rate";
import DefinitionSalePeriods from "./sale-periods";
import DefinitionRegistrationPeriods from "./registration-periods";
import DefinitionSmClasses from "./sm-classes";

export default [
  ...DefinitionChannels,
  ...DefinitionPackages,
  ...DefinitionTrainings,
  ...DefinitionCampaigns,
  ...DefinitionMessageTemplates,
  ...DefinitionInterestRate,
  ...DefinitionSalePeriods,
  ...DefinitionRegistrationPeriods,
  ...DefinitionSmClasses,
];
