/**
 * Route - Channels
 */
export default [
  {
    path: "packages",
    name: "def-packages",
    component: () => import("@/view/pages/definitions/packages/PackageList"),
    meta: {
      requiresAuth: true,
      permission: {
        permissions: ["productions-read"],
      },
    },
  },
  {
    path: "packages/edit/:id?",
    name: "def-package-edit",
    component: () => import("@/view/pages/definitions/packages/PackageEdit"),
    meta: {
      requiresAuth: true,
      permission: {
        permissions: ["productions-update"],
      },
    },
  },
];
