/**
 * Route - Channels
 */

export default [
  {
    path: "channels",
    name: "def-channels",
    component: () => import("@/view/pages/definitions/channels/ChannelList"),
    meta: {
      requiresAuth: true,
      permission: {
        permissions: ["channels-read"],
      },
    },
  },
  {
    path: "channels/edit/:id?",
    name: "def-channels-edit",
    component: () => import("@/view/pages/definitions/channels/ChannelEdit"),
    meta: {
      requiresAuth: true,
      permission: {
        permissions: ["channels-update"],
      },
    },
  },
];
