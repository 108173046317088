import AxiosInstance from "@/core/services/axios.service";
// Action Types
export const FETCH_REMINDERS = "fetchReminders";
export const GET_REMINDER = "getReminder";
export const DELETE_REMINDER = "deleteReminder";
export const CREATE_REMINDER = "createReminder";
export const UPDATE_REMINDER = "updateReminder";
export const COMPLETE_REMINDER_STATUS = "completeReminderStatus";
export const DELETE_COMPLETED_REMINDERS = "deleteCompletedReminders";

// Mutations Types
const SET_REMINDERS = "setReminders";
const SET_REMINDER = "setReminder";
const REMOVE_REMINDER = "removeReminder";
const EDIT_REMINDER = "editReminder";
const ADD_REMINDER = "addReminder";
const COMPLETE_REMINDER = "completeReminder";
const DELETE_COMPLETED = "deleteCompleted";
const SET_SHOW_REMINDER_ALERT = "setShowReminderAlert";
const SET_HIDE_REMINDER_ALERT = "setHideReminderAlert";

const state = {
  reminders: [],
  reminder: {},
  showReminderAlert: false,
};

const getters = {
  reminders(state) {
    return state.reminders;
  },
  inProgressRemindersCount(state) {
    return state.reminders.filter(
      (reminder) => reminder.status === "in_progress"
    ).length;
  },
  getCurrentReminder(state) {
    return state.reminder;
  },
  showReminderAlert(state) {
    return state.showReminderAlert;
  },
};

const actions = {
  [FETCH_REMINDERS]({ commit }) {
    return new Promise((resolve, reject) => {
      AxiosInstance.get("reminders")
        .then(({ data }) => {
          commit(SET_REMINDERS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [GET_REMINDER]({ commit }, id) {
    return new Promise((resolve, reject) => {
      AxiosInstance.get(`reminders/${id}`)
        .then(({ data }) => {
          commit(SET_REMINDER, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [DELETE_REMINDER]({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      AxiosInstance.delete(`reminders/${id}`)
        .then(({ data }) => {
          commit(REMOVE_REMINDER, id);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [CREATE_REMINDER]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AxiosInstance.post("reminders", payload)
        .then(({ data }) => {
          commit(ADD_REMINDER, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [COMPLETE_REMINDER_STATUS]({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      AxiosInstance.patch(`reminders/${id}/completed`)
        .then(({ data }) => {
          commit(COMPLETE_REMINDER, id);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [DELETE_COMPLETED_REMINDERS]({ commit }) {
    return new Promise((resolve, reject) => {
      AxiosInstance.delete("reminders/completed")
        .then(({ data }) => {
          commit(DELETE_COMPLETED);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [UPDATE_REMINDER]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AxiosInstance.put(`reminders/${payload.id}`, payload)
        .then(({ data }) => {
          commit(EDIT_REMINDER, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
};

const mutations = {
  [SET_REMINDERS](state, reminders) {
    state.reminders = reminders;
  },
  [SET_REMINDER]({ state }, reminder) {
    state.reminder = reminder;
  },
  [REMOVE_REMINDER](state, id) {
    state.reminders = state.reminders.filter((reminder) => reminder.id !== id);
  },
  [ADD_REMINDER](state, reminder) {
    state.reminders.push(reminder);
  },
  [COMPLETE_REMINDER](state, id) {
    const reminder = state.reminders.find((r) => r.id === id);
    reminder.status = "completed";
  },
  [DELETE_COMPLETED](state) {
    state.reminders = state.reminders.filter((r) => r.status !== "completed");
  },
  [EDIT_REMINDER](state, reminder) {
    const index = state.reminders.findIndex((r) => r.id === reminder.id);
    state.reminders.splice(index, 1, reminder);
  },
  [SET_SHOW_REMINDER_ALERT](state, e) {
    state.reminder = e.reminder;
    state.showReminderAlert = true;
  },
  [SET_HIDE_REMINDER_ALERT](state) {
    state.reminder = {};
    state.showReminderAlert = false;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
