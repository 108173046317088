export default [
  {
    path: "",
    name: "lead-match-list",
    meta: {
      permission: {
        permissions: ["leads-read"],
      },
      requiresAuth: true,
    },
    component: () => import("@/view/pages/leads-match/LeadsMatchList.vue"),
  },
];
