export default [
  {
    path: "",
    name: "lead-list",
    component: () => import("@/view/pages/leads/LeadList"),
    meta: {
      requiresAuth: true,
      permission: {
        permissions: ["leads-read"],
      },
    },
  },
  {
    path: "show/:id",
    name: "lead-show",
    component: () => import("@/view/pages/leads/LeadShow"),
    meta: {
      requiresAuth: true,
      permission: {
        permissions: ["leads-read"],
      },
    },
  },
  {
    path: "edit/:id",
    name: "lead-edit",
    component: () => import("@/view/pages/leads/LeadEdit"),
    meta: {
      requiresAuth: true,
      permission: {
        permissions: ["leads-update"],
      },
    },
  },
  {
    path: "create",
    name: "lead-create",
    component: () => import("@/view/pages/leads/LeadEdit"),
    meta: {
      requiresAuth: true,
      permission: {
        permissions: ["leads-create"],
      },
    },
  },
];
