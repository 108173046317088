import AxiosInstance from "@/core/services/axios.service";

// Action Types
export const FETCH_PACKAGES = "fetchPackages";
export const GET_PACKAGE = "getPackage";
export const DELETE_PACKAGE = "deletePackage";
export const UPDATE_PACKAGE = "updatePackage";
export const CREATE_PACKAGE = "createPackage";
export const GET_ACTIVE_PACKAGES = "getActivePackages";

// Mutations Types
const SET_PACKAGES = "setPackages";
const SET_PACKAGE = "setPackage";
const EDIT_PACKAGE = "editPackage";
const ADD_PACKAGE = "addPackage";
const REMOVE_PACKAGE = "removePackage";
const state = {
  packages: [],
  package: {},
};

const getters = {
  packages(state) {
    return state.packages;
  },
  package(state) {
    return state.package;
  },
};

const actions = {
  [FETCH_PACKAGES]({ commit }) {
    return new Promise((resolve, reject) => {
      AxiosInstance.get("productions")
        .then(({ data }) => {
          commit(SET_PACKAGES, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [GET_ACTIVE_PACKAGES]({ commit }) {
    return new Promise((resolve, reject) => {
      AxiosInstance.get("active-productions")
        .then(({ data }) => {
          commit(SET_PACKAGES, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [GET_PACKAGE]({ commit }, id) {
    return new Promise((resolve, reject) => {
      AxiosInstance.get(`productions/${id}`)
        .then(({ data }) => {
          commit(SET_PACKAGE, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [CREATE_PACKAGE]({ commit }, currentPackage) {
    return new Promise((resolve, reject) => {
      AxiosInstance.post("productions", currentPackage)
        .then(({ data }) => {
          commit(ADD_PACKAGE, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [UPDATE_PACKAGE]({ commit }, updatedPackage) {
    return new Promise((resolve, reject) => {
      AxiosInstance.put(`productions/${updatedPackage.id}`, updatedPackage)
        .then(({ data }) => {
          commit(EDIT_PACKAGE, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [DELETE_PACKAGE]({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      AxiosInstance.delete(`productions/${id}`)
        .then(({ data }) => {
          commit(REMOVE_PACKAGE, id);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
};

const mutations = {
  [SET_PACKAGES](state, packages) {
    state.packages = packages;
  },
  [SET_PACKAGE](state, currentPackage) {
    state.package = currentPackage;
  },
  [EDIT_PACKAGE](state, updatedPackage) {
    let currentPackage = state.packages.find(
      (mt) => mt.id === updatedPackage.id
    );
    state.packages.splice(
      state.packages.indexOf(currentPackage),
      1,
      updatedPackage
    );
  },
  [ADD_PACKAGE](state, currentPackage) {
    state.packages.push(currentPackage);
  },
  [REMOVE_PACKAGE](state, currentPackageId) {
    state.packages.filter((mt) => mt.id !== currentPackageId);
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
