import AxiosInstance from "../axios.service";
import store from "./index.js";

// Action Types
export const FETCH_NOTES = "fetchNotes";
export const GET_NOTE = "getNote";
export const CREATE_NOTE = "createNote";
export const UPDATE_NOTE = "updateNote";
export const DELETE_NOTE = "deleteNote";

// Mutations Types
const SET_NOTES = "setNotes";
const SET_NOTE = "setNote";
const ADD_NOTE = "addNote";
const EDIT_NOTE = "editNote";
const REMOVE_NOTE = "removeNote";
const CLEAR_NOTE = "clearStateNote";

const state = {
  notes: [],
  note: {},
};

const getters = {
  notes(state) {
    return state.notes;
  },
  note(state) {
    return state.note;
  },
};

const actions = {
  [FETCH_NOTES]({ commit }, params) {
    return new Promise((resolve, reject) => {
      AxiosInstance.get("notes", { params: params })
        .then(({ data }) => {
          commit(SET_NOTES, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [GET_NOTE]({ commit }, id) {
    return new Promise((resolve, reject) => {
      AxiosInstance.get(`notes/${id}`)
        .then(({ data }) => {
          commit(SET_NOTE, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [CREATE_NOTE]({ commit }, note) {
    return new Promise((resolve, reject) => {
      AxiosInstance.post("notes", note)
        .then(({ data }) => {
          commit(ADD_NOTE, data);
          if (data.noteable_type === "App\\Models\\Lead") {
            store.dispatch("updateLeadsNotesCount", {
              id: data.noteable_id,
              note_type: data.note_type,
              fromLeadShowPage: note.get("fromLeadShowPage"),
            });
          }
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  /**
   *
   * @param commit
   * @param updatedNote FormData
   * @returns {Promise<unknown>}
   */
  [UPDATE_NOTE]({ commit }, updatedNote) {
    return new Promise((resolve, reject) => {
      updatedNote.append("_method", "PUT");
      AxiosInstance.post(`notes/${updatedNote.get("id")}`, updatedNote)
        .then(({ data }) => {
          commit(EDIT_NOTE, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [DELETE_NOTE]({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      AxiosInstance.delete(`notes/${id}`)
        .then(({ data }) => {
          commit(REMOVE_NOTE, id);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
};

const mutations = {
  [SET_NOTES](state, notes) {
    state.notes = notes;
  },
  [SET_NOTE](state, note) {
    state.note = note;
  },
  [ADD_NOTE](state, note) {
    state.notes.unshift(note);
  },
  [EDIT_NOTE](state, updatedNote) {
    const note = state.notes.find((n) => n.id === updatedNote.id);
    state.notes.splice(state.notes.indexOf(note), 1, updatedNote);
  },
  [REMOVE_NOTE](state, id) {
    state.notes = state.notes.filter((n) => n.id !== id);
  },
  [CLEAR_NOTE](state) {
    state.note = {};
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
