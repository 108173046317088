import Vue from "vue";

import store from "../services/store";

Vue.directive("role", {
  bind: function (el, binding) {
    const userRoles = store.getters.getCurrentUserRoles;
    const { roles = [] } = binding.value;

    const hasRole =
      roles.length > 0 ? roles.some((role) => userRoles.includes(role)) : true;

    if (!hasRole) {
      el.style.display = "none";
    }
  },
});
