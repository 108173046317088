import AxiosInstance from "@/core/services/axios.service";

// Action Types
export const FETCH_USERS = "fetchUsers";
export const GET_USER = "getUser";
export const CREATE_USER = "createUser";
export const REMOVE_USER = "removeUser";
export const UPDATE_USER = "updateUser";

// Mutations Types
const SET_USERS = "setUsers";
const SET_USER = "setUser";
const ADD_USER = "addUser";
const EDIT_USER = "editUser";
const PURGE_USER = "purgeUsers";

const state = {
  users: [],
  user: {},
};

const getters = {
  users(state) {
    return state.users;
  },
  user(state) {
    return state.user;
  },
  employees(state) {
    return state.users.filter(
      (user) => user?.roles?.length > 0 && user?.roles?.[0]?.name !== "student"
    );
  },
  students(state) {
    return state.users.filter(
      (user) =>
        user?.roles?.length === 0 || user?.roles?.[0]?.name === "student"
    );
  },
};

const actions = {
  [FETCH_USERS]({ commit }) {
    return new Promise((resolve, reject) => {
      AxiosInstance.get("users")
        .then(({ data }) => {
          commit(SET_USERS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [GET_USER]({ commit }, id) {
    return new Promise((resolve, reject) => {
      AxiosInstance.get(`users/${id}`)
        .then(({ data }) => {
          commit(SET_USER, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [CREATE_USER]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AxiosInstance.post("users", payload)
        .then(({ data }) => {
          commit(ADD_USER, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [REMOVE_USER]({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      AxiosInstance.delete(`users/${id}`)
        .then(({ data }) => {
          commit(PURGE_USER, id);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [UPDATE_USER]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AxiosInstance.put(`users/${payload.id}`, payload)
        .then(({ data }) => {
          commit(EDIT_USER, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
};

const mutations = {
  [SET_USERS](state, users) {
    state.users = users;
  },
  [PURGE_USER](state, id) {
    state.users = state.users.filter((user) => user.id != id);
  },
  [SET_USER](state, user) {
    state.user = user;
  },
  [ADD_USER](state, user) {
    state.users.push(user);
  },
  [EDIT_USER](state, updatedUser) {
    const user = state.users.find((u) => u.id == updatedUser.id);
    state.users.splice(state.users.indexOf(user), 1, updatedUser);
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
