export default [
  {
    path: "",
    name: "user-offers-list",
    component: () => import("@/view/pages/user/offers/OfferList.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "view/:id",
    name: "user-offers-view",
    component: () => import("@/view/pages/user/offers/OfferView.vue"),
    meta: { requiresAuth: true },
  },
];
