export default [
  {
    path: "",
    name: "student-list",
    component: () => import("@/view/pages/students/StudentList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];
