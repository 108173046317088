<template>
  <v-menu v-model="menu" left offset-y rounded transition="slide-y-transition">
    <template v-slot:activator="{ on: menu, props }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-icon
            :color="reminderIconColor"
            v-bind="props"
            class="d-flex justify-content-center items-align-center mr-4"
            style="width: 44px"
            v-on="{ ...tooltip, ...menu }"
          >
            mdi-calendar-clock
          </v-icon>
        </template>
        <span>Hatırlatmaları Göster</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-toolbar color="blue" dark>
        <v-toolbar-title class="mx-auto"
          ><span class="text-h5">Hatırlatmalar</span></v-toolbar-title
        >
      </v-toolbar>
      <v-card-text>
        <v-list rounded max-height="300px" width="250px">
          <div
            v-if="reminders.length > 0"
            class="overflow-auto"
            style="height: 250px"
          >
            <v-list-item
              v-for="reminder in mappedReminders"
              :href="reminder.path"
              :key="reminder.id"
              link
              three-line
              :class="getBackgroundColor(reminder.status)"
            >
              <template v-slot:default>
                <v-list-item-content>
                  <v-list-item-title>
                    <strong>{{ reminder.subject }}</strong>
                  </v-list-item-title>

                  <v-list-item-subtitle>
                    <span class="text-primary">{{ reminder.timeAndDate }}</span>
                  </v-list-item-subtitle>

                  <v-list-item-subtitle>
                    {{ reminder.content }}</v-list-item-subtitle
                  >
                </v-list-item-content>

                <v-list-item-action>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click.prevent="markAsCompleted(reminder)"
                      >
                        <v-icon color="green">mdi-check</v-icon>
                      </v-btn>
                    </template>
                    <span>Tamamlandı Olarak İşaretle</span>
                  </v-tooltip>
                  <v-btn icon @click.prevent="reminderDelete(reminder)">
                    <v-icon color="red">mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-action>
              </template>
            </v-list-item>
          </div>
          <div
            style="height: 200px"
            v-else
            class="d-flex flex-column justify-content-center item-aligns-center"
          >
            <v-icon x-large>mdi-calendar-clock</v-icon>
            <span class="text-h5 align-self-center mt-2">{{ message }}</span>
            <v-btn
              v-if="fetch"
              @click="() => fetchReminders()"
              small
              class="ma-5 white--text"
              rounded
              color="indigo"
              dark
            >
              <v-icon left dark> mdi-cloud-upload </v-icon>Yeniden Yükle</v-btn
            >
          </div>
        </v-list>
      </v-card-text>
      <v-card-actions
        v-if="reminders.length > 0"
        class="d-flex justify-content-end"
      >
        <router-link :to="{ name: 'reminder-list' }" custom>
          <v-btn color="primary" text>Hepsini Görüntüle</v-btn>
        </router-link>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  FETCH_REMINDERS,
  COMPLETE_REMINDER_STATUS,
  DELETE_REMINDER,
} from "@/core/services/store/reminders.module";
import { defineComponent } from "vue";

import moment from "moment";
moment.locale("tr");

export default defineComponent({
  name: "TopBarReminderList",
  data() {
    return {
      menu: false,
      reminderIconColor: "blue",
      interval: null,
      message: "",
      fetch: false,
    };
  },
  mounted() {
    this.fetchReminders()
      .then((data) => {
        if (data.length === 0) {
          this.message = "Hatırlatıcı Yok.";
        }
      })
      .catch(() => {
        this.message = "Hatırlatıcılar Yüklenemedi.";
        this.fetch = true;
      });
  },
  updated() {
    if (this.interval === null) {
      this.changeReminderIconColorWithInterval();
    }
  },
  methods: {
    ...mapActions([FETCH_REMINDERS, COMPLETE_REMINDER_STATUS, DELETE_REMINDER]),
    async reminderDelete(reminder) {
      try {
        await this.deleteReminder(reminder);
        this.$toast.success("Hatırlatıcı tamamlandı", {
          position: "bottom-right",
          duration: 2000,
        });
      } catch (error) {
        this.$toast.error(error[0].detail, {
          position: "bottom-right",
          duration: 2000,
        });
      }
    },
    async markAsCompleted(reminder) {
      try {
        await this.completeReminderStatus(reminder);
        this.$toast.success("Hatırlatıcı tamamlandı", {
          position: "bottom-right",
          duration: 2000,
        });
      } catch (error) {
        this.$toast.error(error[0].detail, {
          position: "bottom-right",
          duration: 2000,
        });
      }
    },
    getBackgroundColor(status) {
      switch (status) {
        case "pending":
          return "bg-light-warning";
        case "in_progress":
          return "bg-light-success";
        case "notified":
          return "bg-light-info";
        default:
          return "bg-light-info";
      }
    },
    modifiedCreatedAtForHumans(date, time) {
      const created_at = date + " " + time;
      return moment(created_at).fromNow();
    },
    changeReminderIconColorWithInterval() {
      this.interval = setInterval(() => {
        if (this.inProgressRemindersCount === 0) {
          this.interval = null;
        } else {
          this.reminderIconColor =
            this.reminderIconColor === "warning" ? "blue" : "warning";
        }
      }, 500);
    },
  },
  computed: {
    ...mapGetters(["reminders", "inProgressRemindersCount"]),
    mappedReminders() {
      return this.reminders
        .filter((reminder) => reminder.status !== "completed")
        .map((reminder) => {
          return {
            ...reminder,
            subject: reminder?.note?.subject ?? "Görüşme Kaydı",
            content: reminder?.note?.content ?? "İçerik Yok",
            timeAndDate: this.modifiedCreatedAtForHumans(
              reminder.reminder_date,
              reminder.reminder_time
            ),
            path:
              "https://crm.siliconmadeacademy.com/admin/reminder?reminder_id=" +
              reminder.id,
          };
        });
    },
  },
  watch: {
    inProgressRemindersCount(val) {
      if (val === 0) {
        this.reminderIconColor = "blue";
      }
    },
  },
});
</script>
