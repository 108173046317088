import ApiService from "@/core/services/api.service";
import AxiosInstance from "@/core/services/axios.service";
import JwtService from "@/core/services/jwt.service";

import router from "@/router.js";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const DELETE_STATE = "deleteState";

// mutation types
export const PURGE_AUTH = "purgeAuth";
export const SET_AUTH = "setAuth";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";
export const SET_USER = "setAuthUser";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  getCurrentUserRole(state) {
    return state?.user?.roles[0]?.display_name;
  },
  getCurrentUserRoles(state) {
    return state?.user?.roles.map((role) => role.name);
  },
  getCurrentUserPermissions(state) {
    const userPermissions = state?.user?.permissions.map(
      (permission) => permission.name
    );
    const userRolePermissions = state?.user?.roles?.map((role) =>
      role.permissions.map((permission) => permission.name)
    );
    return [...userPermissions, ...userRolePermissions.flat()];
  },
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      AxiosInstance.post("auth/login", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          ApiService.setHeader();
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response.data.errors);
        });
    });
  },
  [LOGOUT](context) {
    return new Promise((resolve, reject) => {
      AxiosInstance.post("auth/logout")
        .then(() => {
          context.commit(PURGE_AUTH);
          resolve();
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [VERIFY_AUTH](context) {
    const token = JwtService.getToken();
    if (token && token !== "undefined") {
      ApiService.setHeader();
      return new Promise((resolve, reject) => {
        AxiosInstance.get("user/me")
          .then(({ data }) => {
            context.commit(SET_AUTH, data);
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response.data.errors);
            if (!JwtService.getToken()) context.commit(PURGE_AUTH);
            reject(response.data.errors);
          });
      });
    }
  },
  [DELETE_STATE](context) {
    context.commit(PURGE_AUTH);
  },
  redirectAfterLogin({ commit, state }) {
    let toPath = window.localStorage.getItem("redirectAfterLogin");
    if (toPath) {
      commit("clearRedirectAfterLogin");
      router.push(toPath);
    } else {
      const userRoles = state.user.roles;
      if (userRoles.length === 0 || userRoles[0]?.name === "student") {
        router.push({ path: "/user" });
      } else {
        router.push({ path: "/admin" });
      }
    }
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, data) {
    state.isAuthenticated = true;
    state.user = data;
    state.errors = {};
    if (data.token) {
      JwtService.saveToken(data.token);
    }
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
  setRedirectAfterLogin(path) {
    window.localStorage.setItem("redirectAfterLogin", path);
  },
  clearRedirectAfterLogin() {
    window.localStorage.removeItem("redirectAfterLogin");
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
