export default [
  {
    path: "interest-rates",
    name: "def-interest-rates",
    component: () =>
      import("@/view/pages/definitions/interest-rate/InterestRateList.vue"),
    meta: {
      requiresAuth: true,
      permission: {
        permissions: ["interest-rates-read"],
      },
    },
  },
];
