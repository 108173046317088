import DocumentReader from "@/view/content/documents/DocumentRead.vue";

const Reader = {
  install(Vue) {
    this.EventBus = new Vue();

    Vue.component("document-reader", DocumentReader);

    Vue.prototype.$documentReader = {
      show(params) {
        Reader.EventBus.$emit("show", params);
      },
    };
  },
};

export default Reader;
