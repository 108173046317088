import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import moment from "moment";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
import "vue-toastification/dist/index.css";
import "intl-tel-input/build/css/intlTelInput.css";

import VueTelInputVuetify from "vue-tel-input-vuetify/lib";

import Toast from "vue-toastification";
import VueMask, { VueMaskDirective } from "v-mask";
import { LMap, LMarker, LTileLayer } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import { Plugin } from "vue-fragment";

import Modal from "@/core/plugins/approval-modal";
import Reader from "@/core/plugins/document-reader";
import ReminderAlert from "@/view/pages/reminder/ReminderAlert.vue";

import JwtService from "@/core/services/jwt.service";
import "@/core/directives/permission";
import "@/core/directives/roles";
import "@/core/plugins/api";
import AxiosInstance from "@/core/services/axios.service";

import Pusher from "pusher-js";
import VueTelInput from "vue-tel-input";

window.Pusher.logToConsole =
  process.env.NODE_ENV === "production" ? false : true;

const token = JwtService.getToken();

window.Pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
  cluster: "eu",
  encrypted: true,
  authEndpoint: process.env.VUE_APP_PUSHER_AUTH,
  auth: {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  },
});
// Date class configurations
moment.locale("tr");

const ToastOptions = {};
Vue.use(Toast, ToastOptions);

Vue.use(Toast, {});
Vue.use(VueMask);
Vue.use(Plugin);
Vue.use(Modal);
Vue.use(Reader);
Vue.use(VueTelInputVuetify, {
  vuetify,
});
Vue.use(VueTelInput);

Vue.prototype.$apiService = AxiosInstance;
Vue.prototype.$moment = moment;

Vue.component("reminder-alert", ReminderAlert);
Vue.component("l-map", LMap);
Vue.component("l-tile-layer", LTileLayer);
Vue.component("l-marker", LMarker);

Vue.directive("mask", VueMaskDirective);

const vue = new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

window.vue = vue;
