import AxiosInstance from "@/core/services/axios.service";

// Action Types
export const FETCH_CHANNELS = "fetchChannels";
export const GET_CHANNEL = "getChannel";
export const DELETE_CHANNEL = "deleteChannel";
export const UPDATE_CHANNEL = "updateChannel";
export const CREATE_CHANNEL = "createChannel";

// Mutations Types
const SET_CHANNELS = "setChannels";
const SET_CHANNEL = "setChannel";
const EDIT_CHANNEL = "editChannel";
const ADD_CHANNEL = "addChannel";
const REMOVE_CHANNEL = "removeChannel";

const state = {
  channels: [],
  channel: {},
};

const getters = {
  channels(state) {
    return state.channels;
  },
  channel(state) {
    return state.channel;
  },
};

const actions = {
  [FETCH_CHANNELS]({ commit }) {
    return new Promise((resolve, reject) => {
      AxiosInstance.get("channels")
        .then(({ data }) => {
          commit(SET_CHANNELS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [GET_CHANNEL]({ commit }, id) {
    return new Promise((resolve, reject) => {
      AxiosInstance.get(`channels/${id}`)
        .then(({ data }) => {
          commit(SET_CHANNEL, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [DELETE_CHANNEL]({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      AxiosInstance.delete(`channels/${id}`)
        .then(({ data }) => {
          commit(REMOVE_CHANNEL, id);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [UPDATE_CHANNEL]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AxiosInstance.put(`channels/${payload.id}`, payload)
        .then(({ data }) => {
          commit(EDIT_CHANNEL, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [CREATE_CHANNEL]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AxiosInstance.post("channels", payload)
        .then(({ data }) => {
          commit(ADD_CHANNEL, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
};

const mutations = {
  [SET_CHANNELS](state, channels) {
    state.channels = channels;
  },
  [SET_CHANNEL](state, channel) {
    state.channel = channel;
  },
  [REMOVE_CHANNEL](state, id) {
    let channel = state.channels.find((c) => c.id === id);
    state.channels.splice(state.channels.indexOf(channel), 1);
  },
  [EDIT_CHANNEL](state, updatedChannel) {
    let channel = state.channels.find((c) => c.id === updatedChannel.id);
    state.channels.splice(state.channels.indexOf(channel), 1, updatedChannel);
  },
  [ADD_CHANNEL](state, channel) {
    state.channels.push(channel);
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
