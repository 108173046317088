import ApprovalModal from "@/view/content/modals/ApprovalModal.vue";

const Modal = {
  install(Vue) {
    this.EventBus = new Vue();

    Vue.component("approval-modal", ApprovalModal);

    Vue.prototype.$approvalModal = {
      show(params) {
        Modal.EventBus.$emit("show", params);
      },
    };
  },
};

export default Modal;
