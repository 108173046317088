<template>
  <v-card>
    <v-toolbar :color="color" class="rounded-0" :height="height" dark>
      <div class="mr-3" v-if="!modal">
        <slot name="close"></slot>
      </div>
      <div v-if="showTopBar">
        <img
          v-if="asideEnabled && isStudent"
          :src="siteLogo()"
          alt="Logo"
          width="75"
        />
      </div>
      <v-spacer v-if="asideEnabled && isStudent"></v-spacer>
      <v-toolbar-title>
        <h2 class="mb-0" v-html="title"></h2>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <KTTopbar v-if="isStudent && showTopBar"></KTTopbar>

      <slot name="actions"></slot>

      <div class="mr-3" v-if="modal">
        <slot name="close"></slot>
      </div>

      <template v-slot:extension v-if="hasTab">
        <slot name="extension.before"></slot>
        <v-tabs
          v-model="selectedTab"
          :fixed-tabs="fixedTabs"
          :align-with-title="alignWithTitle"
          @change="$emit('change', $event)"
        >
          <v-tabs-slider color="primary lighten-4"></v-tabs-slider>

          <v-tab v-for="item in tabs" :key="item.value">
            {{ item.text }}
          </v-tab>
        </v-tabs>
        <slot name="extension.after"></slot>
      </template>
    </v-toolbar>
    <div :class="{ 'mb-card--disabled': disabled }">
      <fragment v-if="hasTab">
        <v-tabs-items v-model="selectedTab">
          <v-tab-item v-for="item in tabs" :key="item">
            <slot :name="`item.${item.value}`" />
          </v-tab-item>
        </v-tabs-items>
      </fragment>
      <fragment v-else>
        <slot />
      </fragment>
    </div>
  </v-card>
</template>

<script>
import KTTopbar from "@/view/layout/header/Topbar.vue";
import { mapGetters } from "vuex";
import objectPath from "object-path";

export default {
  name: "Card",
  props: {
    title: {
      type: String,
      required: true,
    },
    fixedTabs: {
      type: Boolean,
      default: false,
    },
    alignWithTitle: {
      type: Boolean,
      default: true,
    },
    tabs: Array,
    disabled: Boolean,
    tabDisabled: Boolean,
    modal: {
      type: Boolean,
      default: false,
    },
    defaultTab: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: "primary",
    },
    height: {
      type: String,
      default: "auto",
    },
    showTopBar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      previousTab: null,
      selectedTab: this.defaultTab,
    };
  },
  watch: {
    selectedTab() {
      this.previousTab = this.selectedTab;
      if (this.tabDisabled) {
        this.selectedTab = this.previousTab;
      }
    },
  },
  methods: {
    siteLogo() {
      const menuAsideLeftSkin = this.layoutConfig("brand.self.theme");
      // set brand logo
      const logoObject = this.layoutConfig("self.logo");

      let logo;
      if (typeof logoObject === "string") {
        logo = logoObject;
      }
      if (typeof logoObject === "object") {
        logo = objectPath.get(logoObject, menuAsideLeftSkin + "");
      }
      if (typeof logo === "undefined") {
        const logos = this.layoutConfig("self.logo");
        logo = logos[Object.keys(logos)[0]];
      }
      return process.env.BASE_URL + logo;
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "currentUser"]),
    hasTab() {
      return this.tabs?.length > 0;
    },
    asideEnabled() {
      return this.$vuetify.breakpoint.mobile;
    },
    isStudent() {
      if (this.currentUser?.roles?.length === 0) return true;
      else if (
        this.currentUser?.roles?.length > 0 &&
        this.currentUser?.roles[0]?.name === "student"
      ) {
        return true;
      } else return false;
    },
  },
  components: {
    KTTopbar,
  },
};
</script>

<style scoped>
.mb-card--disabled {
  pointer-events: none;
  opacity: 0.5;
  filter: grayscale(100%);
}
</style>
