<template>
  <v-dialog v-model="dialog" :disabled="disabled" max-width="800">
    <v-card>
      <v-card-title class="text-h5 d-flex justify-content-center flex-column">
        <span class="svg-icon svg-icon-warning svg-icon-6x">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
          >
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect x="0" y="0" width="24" height="24" />
              <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
              <rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
              <rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
            </g>
          </svg>
        </span>
        <span class="mt-2">
          {{ title }}
        </span>
      </v-card-title>
      <v-card-text
        class="d-flex justify-content-center mt-2 text-h6 text-center"
      >
        {{ text }}
      </v-card-text>
      <div v-if="cancelLead">
        <v-card-text
          class="d-flex justify-content-center mt-2 text-h6 text-center"
        >
          <v-select
            v-model="parentResult"
            :items="parentResultCodes"
            item-text="name"
            item-value="id"
            label="Sonuç Kodu"
            outlined
            dense
            class="mr-2"
          ></v-select>
          <v-select
            v-model="childResult"
            :items="childResultCodes"
            item-text="name"
            item-value="id"
            label="Alt Sonuç Kodu"
            outlined
            dense
          ></v-select>
        </v-card-text>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" text @click="cancel"> Hayır </v-btn>
        <v-btn color="red darken-1" text @click="accept"> Evet </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from "vue";
import Modal from "@/core/plugins/approval-modal";
import { mapGetters, mapActions } from "vuex";

export default defineComponent({
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      leadId: null,
      dialog: false,
      content: null,
      onConfirm: {},
      parentResult: null,
      childResult: null,
    };
  },
  computed: {
    ...mapGetters([
      "resultCodes",
      "parentResultCodes",
      "childResultCodesByParentId",
    ]),
    childResultCodes() {
      if (!this.parentResult) {
        return [];
      }
      return this.childResultCodesByParentId(this.parentResult);
    },
  },
  methods: {
    ...mapActions(["updateLeadStatus", "fetchResultCodes"]),
    accept() {
      if (this.cancelLead) {
        this.onConfirm = this.cancel_lead(this.leadId);
        this.cancel();
      } else if (typeof this.onConfirm === "function") {
        this.onConfirm();
        this.cancel();
      } else {
        this.cancel();
      }
    },
    cancel() {
      this.dialog = false;
    },
    show(params) {
      this.dialog = true;
      this.title = params.title;
      this.text = params.text;
      this.leadId = params.leadId;
      this.onConfirm = params.onConfirm;
      this.onReject = params.onReject;
      this.cancelLead = params.cancelLead;
      this.parentResult = null;
      this.childResult = null;
      if (params.cancelLead) {
        this.$store.dispatch("fetchResultCodes");
      }
    },

    async cancel_lead(leadId) {
      try {
        await this.$apiService.post("lead-results", {
          lead_id: leadId,
          result_code_id: this.parentResult,
          sub_result_code_id: this.childResult,
        });
        await this.updateLeadStatus({
          id: leadId,
          status: 12,
          fromLeadShowPage: this.fromLeadShowPage,
        });

        this.$toast.error("Lead iptal edildi.", {
          position: "bottom-right",
          duration: 2000,
        });
        return Promise.resolve();
      } catch (e) {
        this.$toast.error(e[0].detail, {
          position: "bottom-right",
          duration: 2000,
        });
        return Promise.reject(e);
      }
    },
  },

  beforeMount() {
    Modal.EventBus.$on("show", (params) => {
      this.show(params);
    });
  },
});
</script>

<style></style>
