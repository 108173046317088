import AxiosInstance from "../axios.service";

//Action Types
export const FETCH_STUDENTS = "fetchStudents";
export const ASSIGN_STUDENT = "assignStudent";

//Mutation Types
const SET_STUDENTS = "setStudents";
const ASSIGN_STUDENT_TO_COURSE = "assignStudentToCourse";

const state = {
  students: [],
};

const getters = {
  getStudents(state) {
    return state.students;
  },
};

const actions = {
  [FETCH_STUDENTS]({ commit }) {
    return new Promise((resolve, reject) => {
      AxiosInstance.get("students")
        .then(({ data }) => {
          commit(SET_STUDENTS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [ASSIGN_STUDENT]({ commit }, { studentId, courseId }) {
    return new Promise((resolve, reject) => {
      AxiosInstance.post(`students/assign`, {
        sm_class_id: courseId,
        user_id: studentId,
      })
        .then(({ data }) => {
          commit(ASSIGN_STUDENT_TO_COURSE, data?.user);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
};

const mutations = {
  [SET_STUDENTS](state, students) {
    state.students = students;
  },
  [ASSIGN_STUDENT_TO_COURSE](state, student) {
    const stateStudent = state.students.find(
      (stateStudent) => stateStudent.id === student.id
    );
    if (stateStudent) {
      stateStudent.sm_classes = student.sm_classes;
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
