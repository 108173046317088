import AxiosInstance from "@/core/services/axios.service";

// Action Types
export const FETCH_MESSAGE_TEMPLATES = "fetchMessageTemplates";
export const GET_MESSAGE_TEMPLATE = "getMessageTemplate";
export const DELETE_MESSAGE_TEMPLATE = "deleteMessageTemplate";
export const UPDATE_MESSAGE_TEMPLATE = "updateMessageTemplate";
export const CREATE_MESSAGE_TEMPLATE = "createMessageTemplate";

// Mutations Types
const SET_MESSAGE_TEMPLATES = "setMessageTemplates";
const SET_MESSAGE_TEMPLATE = "setMessageTemplate";
const EDIT_MESSAGE_TEMPLATE = "editMessageTemplate";
const ADD_MESSAGE_TEMPLATE = "addMessageTemplate";
const REMOVE_MESSAGE_TEMPLATE = "removeMessageTemplate";

const state = {
  messageTemplates: [],
  messageTemplate: {},
};

const getters = {
  messageTemplates(state) {
    return state.messageTemplates;
  },
  messageTemplate(state) {
    return state.messageTemplate;
  },
};

const actions = {
  [FETCH_MESSAGE_TEMPLATES]({ commit }) {
    return new Promise((resolve, reject) => {
      AxiosInstance.get("message-templates")
        .then(({ data }) => {
          commit(SET_MESSAGE_TEMPLATES, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [GET_MESSAGE_TEMPLATE]({ commit }, id) {
    return new Promise((resolve, reject) => {
      AxiosInstance.get(`message-templates/${id}`)
        .then(({ data }) => {
          commit(SET_MESSAGE_TEMPLATE, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [CREATE_MESSAGE_TEMPLATE]({ commit }, messageTemplate) {
    return new Promise((resolve, reject) => {
      AxiosInstance.post("message-templates", messageTemplate)
        .then(({ data }) => {
          commit(ADD_MESSAGE_TEMPLATE, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [DELETE_MESSAGE_TEMPLATE]({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      AxiosInstance.delete(`message-templates/${id}`)
        .then(({ data }) => {
          commit(REMOVE_MESSAGE_TEMPLATE, id);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [UPDATE_MESSAGE_TEMPLATE]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AxiosInstance.put(`message-templates/${payload.id}`, payload)
        .then(({ data }) => {
          commit(EDIT_MESSAGE_TEMPLATE, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
};

const mutations = {
  [SET_MESSAGE_TEMPLATES](state, messageTemplates) {
    state.messageTemplates = messageTemplates;
  },
  [SET_MESSAGE_TEMPLATE](state, messageTemplate) {
    state.messageTemplate = messageTemplate;
  },
  [EDIT_MESSAGE_TEMPLATE](state, updatedMessageTemplate) {
    let messageTemplate = state.messageTemplates.find(
      (mt) => mt.id === updatedMessageTemplate.id
    );
    state.messageTemplates.splice(
      state.messageTemplates.indexOf(messageTemplate),
      1,
      updatedMessageTemplate
    );
  },
  [ADD_MESSAGE_TEMPLATE](state, messageTemplate) {
    state.messageTemplates.push(messageTemplate);
  },
  [REMOVE_MESSAGE_TEMPLATE](state, id) {
    let messageTemplate = state.messageTemplates.find((mt) => mt.id === id);
    state.messageTemplates.splice(
      state.messageTemplates.indexOf(messageTemplate),
      1
    );
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
