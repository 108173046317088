import Vue from "vue";
import Router from "vue-router";
import store from "./core/services/store";
import routes from "./router/routes";

import { VERIFY_AUTH, DELETE_STATE } from "@/core/services/store/auth.module";

// import middlewarePipeline from "./router/middlewarePipeline";
import JwtService from "./core/services/jwt.service";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes,
});

router.beforeEach(async (to, from, next) => {
  const token = JwtService.getToken();
  const { meta } =
    to.matched.find((record) => {
      if (record.name === undefined) {
        if (record.path === to.path) {
          return record.meta;
        }
      } else if (record.name === to.name) {
        return record.meta;
      }
    }) || {};

  if (to.path.startsWith("/admin")) {
    //Fetch leads
    store.dispatch("fetchLeads");
  }
  if (meta?.requiresAuth && !token) {
    window.localStorage.setItem("redirectAfterLogin", to.path);
    return next({ name: "login" });
  }
  const currentUser = token
    ? await store.dispatch(VERIFY_AUTH).catch(() => {
        store.dispatch(DELETE_STATE);
        return null;
      })
    : null;
  const { permission } = to.meta;
  if (permission) {
    const userPermissions = store.getters.getCurrentUserPermissions;

    const { permissions = [] } = permission;
    const hasPermission =
      permissions.length > 0
        ? permissions.some((permission) => userPermissions.includes(permission))
        : true;

    if (!hasPermission) {
      return next({ path: "/403" });
    }
  }
  // ? Enable this if you want to use middleware pipeline
  // if (meta?.middleware) {
  //   const matchedMiddleware = to.matched
  //     .map((record) => record.meta.middleware)
  //     .filter((middleware) => middleware);
  //   const middleware = Array.isArray(matchedMiddleware)
  //     ? matchedMiddleware.reduce((acc, middleware) => {
  //         if (Array.isArray(middleware)) {
  //           return [...acc, ...middleware];
  //         }
  //         return [...acc, middleware];
  //       }, [])
  //     : [matchedMiddleware];
  //   const context = { from, next, router, to, currentUser };
  //   return middleware[0]({
  //     ...context,
  //     next: middlewarePipeline(context, middleware, 1),
  //   });
  // }

  if (to.name === "login" || to.path === "/") {
    if (
      currentUser &&
      (currentUser.roles.length === 0 ||
        currentUser.roles[0].name === "student")
    ) {
      return next({ path: "/user" });
    } else if (currentUser) {
      return next({ path: "/admin/leads" });
    }
  }

  next();
});

export default router;
