export default [
  {
    path: "sm-classes",
    name: "def-sm-classes",
    component: () => import("@/view/pages/definitions/sm-classes/SMClassList"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "sm-classes/edit/:id?",
    name: "def-sm-classes-edit",
    component: () => import("@/view/pages/definitions/sm-classes/SMClassEdit"),
    meta: {
      requiresAuth: true,
    },
  },
];
