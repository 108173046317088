<template>
  <v-dialog v-model="dialog" max-width="640">
    <template v-slot:activator="{ on: onDialog }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: onTooltip }">
          <v-icon
            @click="dialog = !dialog"
            color="blue"
            style="width: 44px"
            class="d-flex justify-content-center items-align-center mr-4"
            v-on="{ ...onTooltip, ...onDialog }"
          >
            mdi-bug
          </v-icon>
        </template>
        <span>Hata Raporla</span>
      </v-tooltip>
    </template>
    <v-card>
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSchsLe6ug0YXcB14TBbtdqq17L5L1L9VQ97D3EW0Q21roHeKA/viewform?embedded=true"
        width="640"
        height="1414"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
        >Yükleniyor…</iframe
      >
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SM-BugReport",
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
