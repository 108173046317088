import { AdminRouter, UserRouter } from "./index";

export default [
  {
    path: "/admin",
    redirect: "/admin/leads",
    component: () => import("@/view/layout/Layout"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "reports",
        component: () => import("@/view/pages/reports/Reports"),
        children: AdminRouter.RouterReports,
      },
      {
        path: "leads",
        component: () => import("@/view/pages/leads/Leads"),
        children: AdminRouter.RouterLeads,
      },
      {
        path: "users",
        component: () => import("@/view/pages/users/User"),
        children: AdminRouter.RouterUsers,
      },
      {
        path: "students",
        component: () => import("@/view/pages/students/Student"),
        children: AdminRouter.RouterStudents,
      },
      {
        path: "agent-management",
        component: () =>
          import("@/view/pages/agent-management/AgentManagement"),
        children: AdminRouter.RouterAgentManagement,
      },
      {
        path: "definitions",
        meta: { requiresAuth: true },
        component: () => import("@/view/pages/definitions/Definition"),
        children: AdminRouter.RouterDefinitions,
      },
      {
        path: "reminder",
        meta: { requiresAuth: true },
        component: () => import("@/view/pages/reminder/Reminder"),
        children: AdminRouter.RouterReminder,
      },
      {
        path: "notifications",
        component: () => import("@/view/pages/notifications/Notification"),
        children: AdminRouter.RouterNotifications,
      },
      {
        path: "leads-match",
        component: () => import("@/view/pages/leads-match/LeadsMatch"),
        children: AdminRouter.RouterMatch,
      },
      {
        name: "EditProfileAdmin",
        meta: { requiresAuth: true },
        path: "profile",
        component: () => import("@/view/pages/user/profile/Profile"),
      },
    ],
  },
  {
    path: "/user",
    redirect: "/user/dashboard",
    meta: { requiresAuth: true },
    component: () => import("@/view/layout/Layout"),
    children: [
      {
        path: "dashboard",
        component: () => import("@/view/pages/user/Dashboard"),
        meta: { requiresAuth: true },
      },
      {
        path: "offers",
        component: () => import("@/view/pages/user/offers/Offers"),
        children: UserRouter.RouterOffers,
      },
      {
        path: "documents",
        component: () => import("@/view/pages/user/documents/Documents"),
        children: UserRouter.RouterDocuments,
      },
      {
        path: "payments",
        component: () => import("@/view/pages/user/payments/Payments"),
        children: UserRouter.RouterPayments,
      },
      {
        name: "EditProfile",
        path: "profile",
        component: () => import("@/view/pages/user/profile/Profile"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/login",
    component: () => import("@/view/pages/auth/Auth"),
    children: [
      {
        name: "login",
        path: "login",
        component: () => import("@/view/pages/auth/Login"),
        meta: { requiresVisitor: true },
      },
      {
        name: "forgot-password",
        path: "forgotten-password",
        component: () => import("@/view/pages/auth/Forgot-Password"),
        meta: { requiresVisitor: true },
      },
    ],
  },
  {
    path: "/register/:registerToken",
    name: "register",
    component: () => import("@/view/pages/register/Register"),
    children: UserRouter.RouterRegister,
    meta: { requiresVisitor: true },
  },
  {
    path: "/verify",
    name: "EmailVerify",
    component: () => import("@/view/pages/auth/VerifyEmail"),
  },
  {
    path: "/tracker/:token",
    component: () => import("@/view/pages/lead-tracker/LeadTracker"),
    name: "lead-tracker",
  },
  {
    path: "*",
    redirect: "/404",
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/view/pages/error/Error-5.vue"),
  },
  {
    path: "/403",
    name: "403",
    component: () => import("@/view/pages/error/Error-1.vue"),
  },
];
