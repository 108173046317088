import AxiosInstance from "@/core/services/axios.service";

//Action Types
export const FETCH_REGISTRATION_PERIODS = "fetchRegistrationPeriods";
export const DELETE_REGISTRATION_PERIOD = "deleteRegistrationPeriod";
export const GET_REGISTRATION_PERIOD = "getRegistrationPeriod";
export const CREATE_REGISTRATION_PERIOD = "createRegistrationPeriod";
export const UPDATE_REGISTRATION_PERIOD = "updateRegistrationPeriod";

//Mutation Types
const SET_REGISTRATION_PERIODS = "setRegistrationPeriods";
const REMOVE_REGISTRATION_PERIOD = "removeRegistrationPeriod";
const SET_REGISTRATION_PERIOD = "setRegistrationPeriod";
const ADD_REGISTRATION_PERIOD = "addRegistrationPeriod";
const EDIT_REGISTRATION_PERIODS = "editRegistrationPeriods";

const state = {
  registrationPeriods: [],
  registrationPeriod: {},
};

const getters = {
  registrationPeriods(state) {
    return state.registrationPeriods;
  },
  registrationPeriod(state) {
    return state.registrationPeriod;
  },
};

const actions = {
  [FETCH_REGISTRATION_PERIODS]({ commit }) {
    return new Promise((resolve, reject) => {
      AxiosInstance.get("registration-periods")
        .then(({ data }) => {
          commit(SET_REGISTRATION_PERIODS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [DELETE_REGISTRATION_PERIOD]({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      AxiosInstance.delete(`registration-periods/${id}`)
        .then(({ data }) => {
          commit(REMOVE_REGISTRATION_PERIOD, id);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [GET_REGISTRATION_PERIOD]({ commit }, id) {
    return new Promise((resolve, reject) => {
      AxiosInstance.get(`registration-periods/${id}`)
        .then(({ data }) => {
          commit(SET_REGISTRATION_PERIOD, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [CREATE_REGISTRATION_PERIOD]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AxiosInstance.post("registration-periods", payload)
        .then(({ data }) => {
          commit(ADD_REGISTRATION_PERIOD, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [UPDATE_REGISTRATION_PERIOD]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AxiosInstance.put(`registration-periods/${payload.id}`, payload)
        .then(({ data }) => {
          commit(EDIT_REGISTRATION_PERIODS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
};

const mutations = {
  [SET_REGISTRATION_PERIODS](state, registrationPeriods) {
    state.registrationPeriods = registrationPeriods;
  },
  [REMOVE_REGISTRATION_PERIOD](state, id) {
    state.registrationPeriods = state.registrationPeriods.filter(
      (registrationPeriod) => registrationPeriod.id !== id
    );
  },
  [SET_REGISTRATION_PERIOD](state, registrationPeriod) {
    state.registrationPeriod = registrationPeriod;
  },
  [ADD_REGISTRATION_PERIOD](state, registrationPeriod) {
    state.registrationPeriods.unshift(registrationPeriod);
  },
  [EDIT_REGISTRATION_PERIODS](state, registrationPeriod) {
    const index = state.registrationPeriods.findIndex(
      (item) => item.id === registrationPeriod.id
    );
    state.registrationPeriods.splice(index, 1, registrationPeriod);
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
