export default [
  {
    path: "message-templates",
    name: "def-message-templates-list",
    component: () =>
      import("@/view/pages/message-templates/MessageTemplateList"),
    meta: {
      requiresAuth: true,
      permission: {
        permissions: ["message-templates-read"],
      },
    },
  },
  {
    path: "message-templates/edit/:id?",
    name: "def-message-templates-edit",
    component: () =>
      import("@/view/pages/message-templates/MessageTemplateEdit"),
    meta: {
      requiresAuth: true,
      permission: {
        permissions: ["message-templates-update"],
      },
    },
  },
];
