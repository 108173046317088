/**
 * Route - Agent Management
 */
//TODO: Change the permission to the correct one
export default [
  {
    path: "",
    name: "agent-management",
    component: () =>
      import("@/view/pages/agent-management/AgentManagementList"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "edit/:id?",
    name: "agent-management-edit",
    component: () =>
      import("@/view/pages/agent-management/AgentManagementEdit"),
    meta: {
      requiresAuth: true,
    },
  },
];
