export default [
  {
    path: "",
    name: "user-payments-list",
    component: () => import("@/view/pages/user/payments/PaymentList"),
    meta: { requiresAuth: true },
  },
  {
    path: "view/:id",
    name: "user-payments-view",
    component: () => import("@/view/pages/user/payments/PaymentView"),
    meta: { requiresAuth: true },
  },
];
