import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import leads from "./leads.module";
import users from "./users.module";
import reminders from "./reminders.module";
import roles from "./roles.module";
import notes from "./notes.module";
import offers from "./offers.module";
import notifications from "./notifications.module";
import students from "./students.module";
import agents from "./agents.module";

import Definitions from "./definitions/_index";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    leads,
    users,
    reminders,
    roles,
    notes,
    offers,
    notifications,
    students,
    agents,
    ...Definitions,
  },
});
