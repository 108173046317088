import Vue from "vue";

import store from "../services/store";

Vue.directive("permission", {
  bind: function (el, binding) {
    const userPermissions = store.getters.getCurrentUserPermissions;
    const { permissions = [] } = binding.value;

    const hasPermission =
      permissions.length > 0
        ? permissions.some((permission) => userPermissions.includes(permission))
        : true;

    if (!hasPermission) {
      el.style.display = "none";
    }
  },
});
