/**
 * Route - Trainings
 */

export default [
  {
    path: "trainings",
    name: "def-trainings",
    component: () => import("@/view/pages/definitions/trainings/TrainingList"),
    meta: {
      requiresAuth: true,
      permission: {
        permissions: ["educations-read"],
      },
    },
  },
  {
    path: "trainings/edit/:id?",
    name: "def-trainings-edit",
    component: () => import("@/view/pages/definitions/trainings/TrainingEdit"),
    meta: {
      requiresAuth: true,
      permission: {
        permissions: ["educations-update"],
      },
    },
  },
];
