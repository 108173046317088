<template>
  <v-dialog v-model="dialog" persistent eager max-width="650">
    <Card :title="fileName" modal>
      <template #close>
        <a @click="dialog = !dialog">
          <v-btn icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </a>
      </template>
      <img
        style="width: 100%; height: 100%; object-fit: contain"
        v-if="isImage"
        id="image"
        :src="fileURL"
      />
      <audio v-if="isAudio" :src="fileURL" controls></audio>
      <video ref="videoPlayer" v-if="isVideo" :src="fileURL"></video>
      <object
        :data="fileURL"
        v-if="isPDF"
        type="application/pdf"
        width="100%"
        height="700px"
      >
        <p>
          Unable to display PDF file.
          <a :href="fileURL">Download</a>
          instead.
        </p>
      </object>
      <!-- <div v-if="isImage || isVideo" class="range-bar">
      <input
        type="range"
        min="0"
        :max="duration"
        :value="currentTime"
        @input="seek"
      />
    </div> -->
      <div
        class="d-flex justify-content-center pa-4 gap-4"
        v-if="isAudio || isVideo"
      >
        <v-btn
          class="mr-3"
          slot="activator"
          color="primary"
          dark
          @click="playVideo"
        >
          <v-icon>mdi-play</v-icon>
        </v-btn>
        <v-btn
          class="mr-3"
          slot="activator"
          color="primary"
          dark
          @click="pauseVideo"
        >
          <v-icon>mdi-pause</v-icon>
        </v-btn>
        <v-btn
          class="mr-3"
          slot="activator"
          color="primary"
          dark
          @click="loadVideo"
        >
          <v-icon>mdi-reload</v-icon>
        </v-btn>
      </div>
    </Card>
  </v-dialog>
</template>

<script>
// TODO: Video ve Audio player icin seekbar eklenecek
// TODO: En cok kullanilan dosya tipleri icin bilgi alinacak ve gerekli degisiklikler yapilacak
// TODO: Audio player ve videosu olmayan video playerlar icin no video available arka plani eklenicek
// TODO: Fotograf ve pdfler icin zoom in zoom out eklenebilir.

import { defineComponent } from "vue";
import Reader from "@/core/plugins/document-reader";
import Card from "@/view/content/components/Card";

export default defineComponent({
  name: "DocumentReader",
  components: {
    Card,
  },
  data() {
    return {
      dialog: false,
      fileURL: null,
      mimeType: null,
      fileName: null,
      imageTypes: ["image/jpeg", "image/png", "image/jpg"],
      videoTypes: ["video/mp4", "video/avi", "video/mov"],
      audioTypes: ["audio/mpeg", "audio/mp3", "audio/wav"],
    };
  },
  beforeMount() {
    Reader.EventBus.$on("show", (params) => {
      this.show(params);
    });
  },
  updated() {
    if (this.dialog) {
      if (this.fileURL && !this.checkFilePreviewIsAvailable()) {
        this.dialog = false;
        return this.$toast.error(
          "Bu dosya tipi görüntülenme için uygun değil.",
          {
            position: "bottom-right",
            duration: 2000,
          }
        );
      }
    }
  },
  methods: {
    show(params) {
      this.fileURL = params.fileURL;
      this.mimeType = params.mimeType;
      this.fileName = params.fileName;
      this.dialog = true;
    },
    checkFilePreviewIsAvailable() {
      if (this.mimeType == null) return;
      if (
        this.imageTypes.includes(this.mimeType) ||
        this.videoTypes.includes(this.mimeType) ||
        this.audioTypes.includes(this.mimeType) ||
        this.mimeType == "application/pdf"
      )
        return true;
      return false;
    },
    playVideo() {
      const video = document.getElementsByTagName("video")[0];
      video.play();
    },
    pauseVideo() {
      const video = document.getElementsByTagName("video")[0];
      video.pause();
    },
    loadVideo() {
      const video = document.getElementsByTagName("video")[0];
      video.load();
    },
    // seek(event) {
    //   const time = event.target.value;
    //   this.$refs.videoPlayer.currentTime = time;
    // },
  },
  computed: {
    isImage() {
      if (this.mimeType == null) return false;
      return this.imageTypes.includes(this.mimeType);
    },
    isAudio() {
      if (this.mimeType == null) return false;
      return this.audioTypes.includes(this.mimeType);
    },
    isVideo() {
      if (this.mimeType == null) return false;
      return this.videoTypes.includes(this.mimeType);
    },
    isPDF() {
      if (this.mimeType == null) return false;
      if (this.mimeType == "application/pdf") return true;
    },
    // currentTime() {
    //   const video = document.getElementsByTagName("video")[0];
    //   if (!video) return 0;
    //   return video.currentTime;
    // },
    // duration() {
    //   const video = document.getElementsByTagName("video")[0];
    //   if (!video) return 0;
    //   return video.duration;
    // },
  },
});
</script>

<style>
/* .range-bar {
  width: 100%;
  height: 20px;
}

input[type="range"] {
  width: 100%;
  height: 60%;
  -webkit-appearance: none;
  background-color: #ddd;
  outline: none;
  margin: 0;
  padding: 0;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  background-color: #5a2efa;
  border-radius: 50%;
} */
</style>
