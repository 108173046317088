import AxiosInstance from "@/core/services/axios.service";

//Action Types
export const FETCH_SALE_PERIODS = "fetchSalePeriods";
export const DELETE_SALE_PERIOD = "deleteSalePeriod";
export const GET_SALE_PERIOD = "getSalePeriod";
export const CREATE_SALE_PERIOD = "createSalePeriod";
export const UPDATE_SALE_PERIOD = "updateSalePeriod";

//Mutation Types
const SET_SALE_PERIODS = "setSalePeriods";
const REMOVE_SALE_PERIOD = "removeSalePeriod";
const SET_SALE_PERIOD = "setSalePeriod";
const ADD_SALE_PERIOD = "addSalePeriod";
const EDIT_SALE_PERIODS = "editSalePeriods";

const state = {
  salePeriods: [],
  salePeriod: {},
};

const getters = {
  salePeriods(state) {
    return state.salePeriods;
  },
  salePeriod(state) {
    return state.salePeriod;
  },
};

const actions = {
  [FETCH_SALE_PERIODS]({ commit }) {
    return new Promise((resolve, reject) => {
      AxiosInstance.get("sale-periods")
        .then(({ data }) => {
          commit(SET_SALE_PERIODS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [DELETE_SALE_PERIOD]({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      AxiosInstance.delete(`sale-periods/${id}`)
        .then(({ data }) => {
          commit(REMOVE_SALE_PERIOD, id);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [GET_SALE_PERIOD]({ commit }, id) {
    return new Promise((resolve, reject) => {
      AxiosInstance.get(`sale-periods/${id}`)
        .then(({ data }) => {
          commit(SET_SALE_PERIOD, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [CREATE_SALE_PERIOD]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AxiosInstance.post("sale-periods", payload)
        .then(({ data }) => {
          commit(ADD_SALE_PERIOD, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [UPDATE_SALE_PERIOD]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AxiosInstance.put(`sale-periods/${payload.id}`, payload)
        .then(({ data }) => {
          commit(EDIT_SALE_PERIODS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
};

const mutations = {
  [SET_SALE_PERIODS](state, salePeriods) {
    state.salePeriods = salePeriods;
  },
  [REMOVE_SALE_PERIOD](state, id) {
    state.salePeriods = state.salePeriods.filter(
      (salePeriod) => salePeriod.id !== id
    );
  },
  [SET_SALE_PERIOD](state, salePeriod) {
    state.salePeriod = salePeriod;
  },
  [ADD_SALE_PERIOD](state, salePeriod) {
    state.salePeriods.unshift(salePeriod);
  },
  [EDIT_SALE_PERIODS](state, salePeriod) {
    const index = state.salePeriods.findIndex(
      (item) => item.id === salePeriod.id
    );
    state.salePeriods.splice(index, 1, salePeriod);
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
