import AxiosInstance from "@/core/services/axios.service";

// Action Types
export const FETCH_CAMPAIGNS = "fetchCampaigns";
export const GET_CAMPAIGN = "getCampaign";
export const DELETE_CAMPAIGN = "deleteCampaign";
export const UPDATE_CAMPAIGN = "updateCampaign";
export const CREATE_CAMPAIGN = "createCampaign";

// Mutations Types
const SET_CAMPAIGNS = "setCampaigns";
const SET_CAMPAIGN = "setCampaign";
const EDIT_CAMPAIGN = "editCampaign";
const ADD_CAMPAIGN = "addCampaign";
const REMOVE_CAMPAIGN = "removeCampaign";

const state = {
  campaigns: [],
  campaign: {},
};

const getters = {
  campaigns(state) {
    return state.campaigns;
  },
  campaign(state) {
    return state.campaign;
  },
};

const actions = {
  [FETCH_CAMPAIGNS]({ commit }) {
    return new Promise((resolve, reject) => {
      AxiosInstance.get("campaigns")
        .then(({ data }) => {
          commit(SET_CAMPAIGNS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [GET_CAMPAIGN]({ commit }, id) {
    return new Promise((resolve, reject) => {
      AxiosInstance.get(`campaigns/${id}`)
        .then(({ data }) => {
          commit(SET_CAMPAIGN, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [DELETE_CAMPAIGN]({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      AxiosInstance.delete(`campaigns/${id}`)
        .then(({ data }) => {
          commit(REMOVE_CAMPAIGN, id);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [UPDATE_CAMPAIGN]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AxiosInstance.put(`campaigns/${payload.id}`, payload)
        .then(({ data }) => {
          commit(EDIT_CAMPAIGN, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [CREATE_CAMPAIGN]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AxiosInstance.post("campaigns", payload)
        .then(({ data }) => {
          commit(ADD_CAMPAIGN, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
};

const mutations = {
  [SET_CAMPAIGNS](state, campaigns) {
    state.campaigns = campaigns;
  },
  [SET_CAMPAIGN](state, campaign) {
    state.campaign = campaign;
  },
  [REMOVE_CAMPAIGN](state, id) {
    let campaign = state.campaigns.find((c) => c.id === id);
    state.campaigns.splice(state.campaigns.indexOf(campaign), 1);
  },
  [EDIT_CAMPAIGN](state, updatedCampaign) {
    let campaign = state.campaigns.find((c) => c.id === updatedCampaign.id);
    state.campaigns.splice(
      state.campaigns.indexOf(campaign),
      1,
      updatedCampaign
    );
  },
  [ADD_CAMPAIGN](state, campaign) {
    state.campaigns.push(campaign);
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
