export default [
  {
    path: "",
    name: "register-landing-page",
    component: () => import("@/view/pages/register/LandingPage"),
    meta: { requiresAuth: false },
  },
  {
    path: "form",
    name: "register-form",
    component: () => import("@/view/pages/register/Form"),
    meta: { requiresAuth: false },
  },
  {
    path: "success",
    name: "register-success",
    component: () => import("@/view/pages/register/EndingPage"),
    meta: { requiresAuth: false },
  },
];
