export default [
  {
    path: "sale-periods",
    name: "def-sale-periods",
    component: () =>
      import("@/view/pages/definitions/sale-periods/SalePeriodList"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "sale-periods/edit/:id?",
    name: "def-sale-periods-edit",
    component: () =>
      import("@/view/pages/definitions/sale-periods/SalePeriodEdit"),
    meta: {
      requiresAuth: true,
    },
  },
];
