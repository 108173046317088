import AxiosInstance from "../axios.service";
import store from "./index";

// ACTION TYPES
export const FETCH_OFFERS = "fetchOffers";
export const GET_OFFER = "getOffer";
export const CREATE_OFFER = "createOffer";
export const UPDATE_OFFER = "updateOffer";
export const DELETE_OFFER = "deleteOffer";

// MUTATION TYPES
const SET_OFFERS = "setOffers";
const SET_OFFER = "setOffer";
const ADD_OFFER = "addOffer";
const EDIT_OFFER = "editOffer";
const REMOVE_OFFER = "removeOffer";

// STATE
const state = {
  offers: [],
  offer: {},
};

// GETTERS
const getters = {
  offers(state) {
    return state.offers;
  },
  offer(state) {
    return state.offer;
  },
};

// ACTIONS
const actions = {
  [FETCH_OFFERS]({ commit }, params) {
    return new Promise((resolve, reject) => {
      AxiosInstance.get("users/offers", { params: params })
        .then(({ data }) => {
          commit(SET_OFFERS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [GET_OFFER]({ commit }, offerId) {
    return new Promise((resolve, reject) => {
      AxiosInstance.get(`offers/${offerId}`)
        .then(({ data }) => {
          commit(SET_OFFER, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [CREATE_OFFER]({ commit }, offer) {
    return new Promise((resolve, reject) => {
      AxiosInstance.post("offers", offer)
        .then(({ data }) => {
          commit(ADD_OFFER, data);
          if (data.offer_status === "pending") {
            store.commit("editLeadStatus", {
              id: data.lead_id,
              status: 5,
              fromLeadShowPage: offer.fromLeadShowPage,
            });
          } else {
            store.commit("editLeadStatus", {
              id: data.lead_id,
              status: 4,
              fromLeadShowPage: offer.fromLeadShowPage,
            });
          }
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [UPDATE_OFFER]({ commit }, updatedOffer) {
    return new Promise((resolve, reject) => {
      AxiosInstance.put(`offers/${updatedOffer.id}`, updatedOffer)
        .then(({ data }) => {
          commit(EDIT_OFFER, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [DELETE_OFFER]({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      AxiosInstance.delete(`offers/${id}`)
        .then(({ data }) => {
          commit(REMOVE_OFFER, id);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
};

// MUTATIONS
const mutations = {
  [SET_OFFERS](state, offers) {
    state.offers = offers;
  },
  [SET_OFFER](state, offer) {
    state.offer = offer;
  },
  [ADD_OFFER](state, offer) {
    state.offers.push(offer);
  },
  [EDIT_OFFER](state, updatedOffer) {
    let offer = state.offers.find((o) => o.id === updatedOffer.id);
    state.offers.splice(state.offers.indexOf(offer), 1, updatedOffer);
  },
  [REMOVE_OFFER](state, id) {
    state.offers = state.offers.filter((o) => o.id !== id);
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
