import Channels from "./channels.module";
import Packages from "./packages.module";
import Trainings from "./trainings.module";
import Campaigns from "./campaigns.module";
import MessageTemplates from "./message-templates.module";
import SalePeriods from "./sale-periods.module";
import RegistrationPeriods from "./registration-periods.module";
import SmClasses from "./sm-classes.module";
import ResultCodes from "./result-codes.module";

export default {
  channels: Channels,
  packages: Packages,
  trainings: Trainings,
  campaigns: Campaigns,
  messageTemplates: MessageTemplates,
  salePeriods: SalePeriods,
  registrationPeriods: RegistrationPeriods,
  smClasses: SmClasses,
  resultCodes: ResultCodes,
};
