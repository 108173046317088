/**
 * Route - Campaigns
 */
export default [
  {
    path: "campaigns",
    name: "def-campaigns",
    component: () => import("@/view/pages/definitions/campaigns/CampaignList"),
    meta: {
      requiresAuth: true,
      permission: {
        permissions: ["campaigns-read"],
      },
    },
  },
  {
    path: "campaigns/edit/:id?",
    name: "def-campaigns-edit",
    component: () => import("@/view/pages/definitions/campaigns/CampaignEdit"),
    meta: {
      requiresAuth: true,
      permission: {
        permissions: ["campaigns-update"],
      },
    },
  },
];
