import AxiosInstance from "@/core/services/axios.service";

// Action Types
export const FETCH_RESULT_CODES = "fetchResultCodes";

// Mutations Types
const SET_RESULT_CODES = "setResultCodes";

const state = {
  resultCodes: [],
  resultCode: {},
  childResultCodes: [],
};

const getters = {
  resultCodes(state) {
    return state.resultCodes;
  },
  parentResultCodes(state) {
    return state.resultCodes.filter((code) => code.parent_id === null);
  },
  childResultCodesByParentId: (state) => (parentId) => {
    return state.resultCodes.filter((code) => code.parent_id === parentId);
  },
};

const actions = {
  [FETCH_RESULT_CODES]({ commit }) {
    return new Promise((resolve, reject) => {
      AxiosInstance.get("result-codes")
        .then(({ data }) => {
          commit(SET_RESULT_CODES, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
};

const mutations = {
  [SET_RESULT_CODES](state, resultCodes) {
    state.resultCodes = resultCodes;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
