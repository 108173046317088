<template>
  <v-dialog class="shake" v-model="showReminderAlert" width="500" persistent>
    <v-card>
      <v-card-title class="text-h5 d-flex justify-content-center flex-column">
        <span class="svg-icon svg-icon-warning svg-icon-6x">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
          >
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect x="0" y="0" width="24" height="24" />
              <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
              <rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
              <rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
            </g>
          </svg>
        </span>
        <span class="mt-2">
          {{ title }}
        </span>
      </v-card-title>
      <v-card-text
        class="d-flex justify-content-center mt-2 text-h6 text-center"
      >
        {{ message }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="dismiss"> Yoksay </v-btn>
        <v-btn color="primary" @click="accept"> Hatırlatıcıyı Gör </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { defineComponent } from "vue";
import { isEmptyObject } from "jquery";
import store from "@/core/services/store";

/**
 * TODO: Component render edildiginde alarm calismali.
 */

export default defineComponent({
  name: "ReminderAlert",
  data() {
    return {
      title: "Hatırlatma",
      message:
        "Yarattığınız bir not için hatırlatıcı kurmuştunuz. Hatırlatıcıyı görmek ister misiniz?",
      titleInterval: null,
      shakeIntervalId: null,
      documentTitle: null,
    };
  },
  created() {
    const interval = setInterval(() => {
      if (!isEmptyObject(this.currentUser)) {
        if (this.currentUser.roles.length > 0) {
          this.subscribeToReminderChannel();
        }
        clearInterval(interval);
      }
    }, 500);
  },
  methods: {
    subscribeToReminderChannel() {
      var channel = window.Pusher.subscribe(`reminder.${this.currentUser.id}`);
      channel.bind("reminder.alert", function (e) {
        store.commit("setShowReminderAlert", e);
      });
    },
    dismiss() {
      store.commit("setHideReminderAlert");
      this.stopTitleChange();
      document.title = this.documentTitle;
      this.stopAlarm();
    },
    accept() {
      this.stopTitleChange();
      const props = this.$router.resolve({
        name: "reminder-list",
        query: { reminder_id: this.getCurrentReminder.id },
      });
      window.open(props.href);
      this.dismiss();
    },
    startAlarm() {
      // const audio = new Audio("alarm.mp3");
      // audio.loop = true;
      // audio.play();
      // Change document title when alarm is ringing
      this.startTitleChange();
      this.startShake();
    },
    stopAlarm() {
      // const audio = document.getElementsByTagName("audio")[0];
      // audio.pause();
      // audio.currentTime = 0;
      this.stopShake();
    },
    startShake() {
      let element;
      setTimeout(() => {
        element = document.getElementsByClassName("v-dialog")[0];
      }, 500);
      const intervalId = setInterval(() => {
        element.classList.toggle("shake");
      }, 500);
      this.shakeIntervalId = intervalId;
    },
    stopShake() {
      clearInterval(this.shakeIntervalId);
      const element = document.getElementsByClassName("v-dialog")[0];
      element.classList.remove("shake");
    },
    startTitleChange() {
      this.documentTitle = document.title;

      this.titleInterval = setInterval(() => {
        if (document.title === this.documentTitle) {
          document.title = "Hatırlatıcı Uyarısı!";
        } else {
          document.title = this.documentTitle;
        }
      }, 1000);
    },
    stopTitleChange() {
      clearInterval(this.titleInterval);
      this.titleInterval = null;
    },
  },
  computed: {
    ...mapGetters(["currentUser", "showReminderAlert", "getCurrentReminder"]),
  },
  watch: {
    showReminderAlert: {
      handler: function (val) {
        if (val) {
          this.startAlarm();
        }
      },
      immediate: true,
    },
  },
});
</script>

<style>
.shake {
  animation: shake 0.5s;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
