import AxiosInstance from "@/core/services/axios.service";

// Action Types
export const FETCH_TRAININGS = "fetchTrainings";
export const GET_TRAINING = "getTraining";
export const DELETE_TRAINING = "deleteTraining";
export const UPDATE_TRAINING = "updateTraining";
export const CREATE_TRAINING = "createTraining";

// Mutations Types
const SET_TRAININGS = "setTrainings";
const SET_TRAINING = "setTraining";
const EDIT_TRAINING = "editTraining";
const ADD_TRAINING = "addTraining";
const REMOVE_TRAINING = "removeTraining";

const state = {
  trainings: [],
  training: {},
};

const getters = {
  trainings(state) {
    return state.trainings;
  },
  training(state) {
    return state.training;
  },
};

const actions = {
  [FETCH_TRAININGS]({ commit }) {
    return new Promise((resolve, reject) => {
      AxiosInstance.get("educations")
        .then(({ data }) => {
          commit(SET_TRAININGS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [GET_TRAINING]({ commit }, id) {
    return new Promise((resolve, reject) => {
      AxiosInstance.get(`educations/${id}`)
        .then(({ data }) => {
          commit(SET_TRAINING, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [CREATE_TRAINING]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AxiosInstance.post("educations", payload)
        .then(({ data }) => {
          commit(ADD_TRAINING, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [UPDATE_TRAINING]({ commit }, updatedTraining) {
    return new Promise((resolve, reject) => {
      AxiosInstance.put(
        `educations/${parseInt(updatedTraining.id)}`,
        updatedTraining
      )
        .then(({ data }) => {
          commit(EDIT_TRAINING, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [DELETE_TRAINING]({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      AxiosInstance.delete(`educations/${id}`)
        .then(({ data }) => {
          commit(REMOVE_TRAINING, id);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
};

const mutations = {
  [SET_TRAININGS](state, trainings) {
    state.trainings = trainings;
  },
  [SET_TRAINING](state, training) {
    state.training = training;
  },
  [ADD_TRAINING](state, training) {
    state.trainings.push(training);
  },
  [EDIT_TRAINING](state, updatedTraining) {
    let training = state.trainings.find((t) => t.id === updatedTraining.id);
    state.trainings.splice(
      state.trainings.indexOf(training),
      1,
      updatedTraining
    );
  },
  [REMOVE_TRAINING](state, id) {
    state.trainings.filter((t) => t.id !== id);
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
