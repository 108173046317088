import axios from "axios";
import JwtService from "./jwt.service";
import store from "./store";
import router from "@/router.js";

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BACKEND,
  timeout: 0,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + JwtService.getToken(),
  },
});

// Add a request interceptor to set the JWT token in the headers
axiosInstance.interceptors.request.use(
  (config) => {
    if (JwtService.getToken()) {
      config.headers["Authorization"] = "Bearer " + JwtService.getToken();
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Add a response interceptor to handle unauthorized requests
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      store.commit("purgeAuth");
      router.push({ name: "login" });
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
