import AxiosInstance from "@/core/services/axios.service";

// Action Types
export const FETCH_AGENTS = "fetchAgents";
export const GET_AGENT = "getAgent";
export const DELETE_AGENT = "deleteAgent";
export const UPDATE_AGENT = "updateAgent";
export const CREATE_AGENT = "createAgent";

// Mutations Types
const SET_AGENTS = "setAgents";
const SET_AGENT = "setAgent";
const EDIT_AGENT = "editAgent";
const ADD_AGENT = "addAgent";
const REMOVE_AGENT = "removeAgent";

const state = {
  agents: [],
  agent: {},
};

const getters = {
  agents(state) {
    return state.agents;
  },
  agent(state) {
    return state.agent;
  },
};

const actions = {
  [FETCH_AGENTS]({ commit }) {
    return new Promise((resolve, reject) => {
      AxiosInstance.get("agents")
        .then(({ data }) => {
          commit(SET_AGENTS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [GET_AGENT]({ commit }, id) {
    return new Promise((resolve, reject) => {
      AxiosInstance.get(`agents/${id}`)
        .then(({ data }) => {
          commit(SET_AGENT, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [DELETE_AGENT]({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      AxiosInstance.delete(`agents/${id}`)
        .then(({ data }) => {
          commit(REMOVE_AGENT, id);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [UPDATE_AGENT]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AxiosInstance.put(`agents/${payload.id}`, payload)
        .then(({ data }) => {
          commit(EDIT_AGENT, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  [CREATE_AGENT]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AxiosInstance.post("agents", payload)
        .then(({ data }) => {
          commit(ADD_AGENT, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
};

const mutations = {
  [SET_AGENTS](state, agents) {
    state.agents = agents;
  },
  [SET_AGENT](state, agent) {
    state.agent = agent;
  },
  [REMOVE_AGENT](state, id) {
    let agent = state.agents.find((c) => c.id === id);
    state.agents.splice(state.agents.indexOf(agent), 1);
  },
  [EDIT_AGENT](state, updatedAgent) {
    let agent = state.agents.find((c) => c.id === updatedAgent.id);
    state.agents.splice(state.agents.indexOf(agent), 1, updatedAgent);
  },
  [ADD_AGENT](state, agent) {
    state.agents.push(agent);
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
