<template>
  <v-menu
    left
    min-width="200px"
    rounded
    offset-y
    transition="slide-y-transition"
  >
    <template v-slot:activator="{ on: menu }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn icon x-large v-on="{ ...tooltip, ...menu }">
            <v-avatar size="35" :color="color">
              <span :class="isStudent ? 'text-h5 text-white' : 'text-h5'">
                {{ userCode }}
              </span>
            </v-avatar>
          </v-btn>
        </template>
        <span>Menüyü Göster</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-toolbar class="pa-2 h-auto" :color="color" dark>
        <v-toolbar-title class="mx-auto text-center d-flex flex-column">
          <h3>{{ userName }}</h3>
          <span class="text-caption">{{ userEmail }}</span>
          <span class="text-caption">{{ userPhone }}</span>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <div v-if="!isStudent">
          <v-chip
            v-for="role in userRoles"
            :key="role.id"
            label
            color="orange"
            small
            class="ma-1"
            text-color="white"
          >
            {{ role.description }}
          </v-chip>
        </div>
        <div v-else-if="isStudent" class="d-flex justify-content-center mb-3">
          <v-chip label color="orange" small text-color="white">
            <v-icon small left> mdi-star </v-icon>
            Öğrenci
          </v-chip>
        </div>
        <div v-else class="d-flex justify-content-center mb-3">
          <v-chip label color="orange" small text-color="white">
            <v-icon small left> mdi-star </v-icon>
            Öğrenci Adayı
          </v-chip>
        </div>
        <div class="mx-auto text-center">
          <v-divider class="my-1"></v-divider>
          <v-btn link :ripple="false" :to="{ name: profilePath }" text>
            Profil Bilgilerim
          </v-btn>
          <v-divider class="my-1"></v-divider>
          <v-btn
            link
            :ripple="false"
            :to="{ name: resetPasswordPath, query: { tab: 1 } }"
            text
          >
            Parola Değiştir
          </v-btn>
          <v-divider class="my-1"></v-divider>
          <fragment v-if="asideEnabled && isStudent">
            <v-btn link :ripple="false" :to="{ path: '/user/offers' }" text>
              Onay Bekleyen İşlemlerim
            </v-btn>
            <v-divider class="my-1"></v-divider>
            <v-btn link :ripple="false" :to="{ path: '/user/payments' }" text>
              Ödemelerim
            </v-btn>
            <v-divider class="my-1"></v-divider>
            <v-btn link :ripple="false" :to="{ path: '/user/documents' }" text>
              Belgelerim
            </v-btn>
            <v-divider class="my-1"></v-divider>
          </fragment>
          <v-btn @click="logoutUser" color="error" text> Çıkış Yap </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { LOGOUT } from "@/core/services/store/auth.module";

export default {
  name: "TopBarAvatarMenu",
  props: {
    color: {
      type: String,
      default: "blue",
    },
  },
  methods: {
    ...mapActions([LOGOUT]),
    async logoutUser() {
      this.loading = true;
      await this.logout();
      this.$router.push({ name: "login" });
      this.loading = false;
    },
  },
  computed: {
    ...mapGetters(["currentUser", "getCurrentUserRoles"]),
    resetPasswordPath: {
      get() {
        return !this.isStudent ? "EditProfileAdmin" : "EditProfile";
      },
    },
    profilePath: {
      get() {
        return !this.isStudent ? "EditProfileAdmin" : "EditProfile";
      },
    },
    userName() {
      return this.currentUser?.name;
    },
    userEmail() {
      return this.currentUser?.email?.email;
    },
    userPhone() {
      return this.currentUser?.phone;
    },
    userRoles() {
      const userRoles = this.currentUser?.roles;
      return userRoles?.length > 0 ? userRoles : null;
    },
    userCode() {
      const nameArr = this.currentUser?.name?.split(" ");
      return nameArr?.length > 1
        ? nameArr[0].charAt(0) + nameArr[1].charAt(0)
        : nameArr[0].charAt(0);
    },
    asideEnabled() {
      return this.$vuetify.breakpoint.mobile;
    },
    isStudent() {
      if (this.currentUser?.roles?.length === 0) return true;
      else if (
        this.currentUser?.roles?.length > 0 &&
        this.currentUser?.roles[0]?.name === "student"
      ) {
        return true;
      } else return false;
    },
  },
};
</script>
```
