<template>
  <v-menu v-model="menu" left offset-y rounded transition="slide-y-transition">
    <template v-slot:activator="{ on: menu, props }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-icon
            :color="notifyIconColor"
            v-bind="props"
            class="d-flex justify-content-center items-align-center mr-4"
            style="width: 44px"
            v-on="{ ...tooltip, ...menu }"
          >
            mdi-alert-circle
          </v-icon>
        </template>
        <span>Bildirimleri Göster</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-toolbar color="blue" dark>
        <v-toolbar-title class="mx-auto"
          ><span class="text-h5">Bildirimler</span></v-toolbar-title
        >
      </v-toolbar>
      <v-card-text>
        <v-list rounded max-height="300px" width="250px">
          <div
            v-if="notifications.length > 0"
            class="overflow-auto"
            style="height: 250px"
          >
            <v-list-item
              :href="notify.data.path"
              v-for="notify in notifications"
              :key="notify.id"
              three-line
              link
              :class="notify.read_at ? 'bg-light-info' : 'bg-light-warning'"
            >
              <template v-slot:default>
                <v-list-item-content>
                  <v-list-item-title>
                    <strong>{{ notify.data.subject }}</strong>
                  </v-list-item-title>

                  <v-list-item-subtitle>
                    <span class="text-primary">{{
                      modifiedCreatedAtForHumans(notify.created_at)
                    }}</span>
                  </v-list-item-subtitle>

                  <v-list-item-subtitle>
                    {{ notify.data.message }}</v-list-item-subtitle
                  >
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn icon @click="notificationDelete(notify.id)">
                    <v-icon color="red">mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-action>
              </template>
            </v-list-item>
            <!-- <v-divider :key="notify.id"></v-divider> -->
          </div>
          <div
            v-else
            class="d-flex flex-column justify-content-center items-align-center"
            style="height: 200px"
          >
            <v-icon x-large>mdi-alert-circle</v-icon>
            <span class="text-h5 align-self-center mt-2">{{ message }}</span>
            <v-btn
              v-if="fetch"
              @click="() => fetchNotifications()"
              small
              class="ma-5 white--text"
              rounded
              color="indigo"
              dark
            >
              <v-icon left dark> mdi-cloud-upload </v-icon>Yeniden Yükle</v-btn
            >
          </div>
        </v-list>
      </v-card-text>
      <v-card-actions
        v-if="notifications.length > 0"
        class="d-flex justify-content-center"
      >
        <v-btn color="error" @click="deleteAll()" text>Hepsini Sil</v-btn>
        <router-link :to="{ name: 'notification-list' }" custom>
          <v-btn color="primary" text>Hepsini Görüntüle</v-btn>
        </router-link>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import {
  FETCH_NOTIFICATIONS,
  MARK_AS_READ,
  DELETE_NOTIFICATION,
  DELETE_ALL_NOTIFICATIONS,
  GET_UNREAD_NOTIFICATIONS_COUNT,
} from "@/core/services/store/notifications.module";
// import { isEmptyObject } from "jquery";
// import store from "@/core/services/store";

import moment from "moment";
moment.locale("tr");

export default defineComponent({
  name: "TopBarNotificationList",
  data() {
    return {
      notifyIconColor: "blue",
      menu: false,
      interval: null,
      message: "",
      fetch: false,
    };
  },
  async mounted() {
    try {
      const notifications = await this.fetchNotifications();
      if (notifications.length === 0) {
        this.message = "Bildirim Yok.";
      }
    } catch {
      this.message = "Bildirimler Yüklenemedi";
      this.fetch = true;
    }
  },
  created() {
    // const interval = setInterval(() => {
    //   if (!isEmptyObject(this.currentUser)) {
    //     this.subscribeToNotificationChannel();
    //     clearInterval(interval);
    //   }
    // }, 500);
    // create a interval for request to unread notifications count api every 1 minutes
  },
  updated() {
    if (this.interval === null) {
      this.changeNotifyIconColorWithInterval();
    }
  },
  methods: {
    ...mapActions([
      FETCH_NOTIFICATIONS,
      MARK_AS_READ,
      DELETE_NOTIFICATION,
      DELETE_ALL_NOTIFICATIONS,
      GET_UNREAD_NOTIFICATIONS_COUNT,
    ]),
    async notificationDelete(notificationID) {
      await this.deleteNotification(notificationID);
    },
    async deleteAll() {
      await this.deleteAllNotifications();
    },
    changeNotifyIconColorWithInterval() {
      this.interval = setInterval(() => {
        if (this.unreadNotificationsCount === 0) {
          this.interval = null;
        } else {
          this.notifyIconColor =
            this.notifyIconColor === "red" ? "blue" : "red";
        }
      }, 500);
    },
    modifiedCreatedAtForHumans(created_at) {
      return moment(created_at).fromNow();
    },
    // subscribeToNotificationChannel() {
    //   var channel = window.Pusher.subscribe(
    //     `notification.${this.currentUser.id}`
    //   );
    //   channel.bind("notification.info", function (e) {
    //     store.commit("addNotification", e);
    //     this.$toast.info("Yeni bildirim", {
    //       position: "top-left",
    //       duration: 2000,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       draggablePercent: 0.6,
    //       showCloseButtonOnHover: false,
    //       hideProgressBar: false,
    //       closeButton: "button",
    //       icon: true,
    //       rtl: false,
    //     });
    //   });
    // },
  },
  computed: {
    ...mapGetters(["notifications", "unreadNotificationsCount", "currentUser"]),
  },
  watch: {
    async menu(newValue, oldValue) {
      if (!newValue && oldValue) {
        await this.markAsRead();
        this.notifyIconColor = "blue";
      }
    },
  },
});
</script>
