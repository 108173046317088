export default [
  {
    path: "",
    name: "user-list",
    component: () => import("@/view/pages/users/UserList"),
    meta: {
      requiresAuth: true,
      permission: {
        permissions: ["users-read"],
      },
    },
  },
  {
    path: "edit/:id",
    name: "user-edit",
    component: () => import("@/view/pages/users/UserEdit"),
    meta: {
      requiresAuth: true,
      permission: {
        permissions: ["users-update"],
      },
    },
  },
  {
    path: "show/:id",
    name: "user-show",
    component: () => import("@/view/pages/users/UserEdit"),
    meta: {
      requiresAuth: true,
      permission: {
        permissions: ["users-read"],
      },
    },
  },
  {
    path: "create",
    name: "user-create",
    component: () => import("@/view/pages/users/UserEdit"),
    meta: {
      requiresAuth: true,
      permission: {
        permissions: ["users-create"],
      },
    },
  },
];
